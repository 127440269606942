import * as React from 'react';

export function ClerkGradient(props) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.337 2.8706L12.1766 5.03102C12.1087 5.09883 12.02 5.14189 11.9247 5.15328C11.8294 5.16467 11.7331 5.14374 11.6511 5.09384C10.8012 4.58116 9.82114 4.32548 8.82915 4.35766C7.83716 4.38984 6.87569 4.7085 6.06085 5.27519C5.55971 5.62402 5.12457 6.05916 4.77574 6.5603C4.20973 7.37563 3.89136 8.33715 3.85893 9.32916C3.82651 10.3212 4.08141 11.3014 4.59296 12.152C4.6425 12.2338 4.66323 12.3298 4.65184 12.4248C4.64046 12.5198 4.59761 12.6082 4.53013 12.676L2.36972 14.8364C2.32621 14.8802 2.27376 14.9141 2.21594 14.9357C2.15813 14.9574 2.09633 14.9662 2.03476 14.9618C1.9732 14.9573 1.91332 14.9396 1.85925 14.9098C1.80517 14.88 1.75816 14.8389 1.72145 14.7893C0.552528 13.1826 -0.0515691 11.2341 0.00345157 9.24793C0.0584722 7.26176 0.769516 5.34969 2.02559 3.81016C2.40906 3.33912 2.83967 2.90851 3.31071 2.52504C4.85012 1.26931 6.76192 0.558472 8.74778 0.503452C10.7336 0.448433 12.6819 1.05232 14.2884 2.2209C14.3384 2.25749 14.3798 2.30448 14.4099 2.35863C14.4399 2.41278 14.4579 2.4728 14.4625 2.53455C14.4671 2.59631 14.4583 2.65833 14.4366 2.71635C14.4149 2.77436 14.3809 2.82698 14.337 2.8706Z"
        fill="url(#paint0_linear_749_26688)"
      />
      <path
        d="M14.3371 16.1217L12.1767 13.9612C12.1088 13.8934 12.0201 13.8504 11.9248 13.839C11.8295 13.8276 11.7332 13.8485 11.6512 13.8984C10.8499 14.3818 9.93182 14.6372 8.996 14.6372C8.06019 14.6372 7.14212 14.3818 6.34081 13.8984C6.25884 13.8485 6.1625 13.8276 6.06721 13.839C5.97193 13.8504 5.88324 13.8934 5.81534 13.9612L3.65492 16.1217C3.60962 16.1652 3.57445 16.2182 3.55192 16.2769C3.52938 16.3355 3.52004 16.3984 3.52454 16.4611C3.52903 16.5238 3.54727 16.5847 3.57794 16.6396C3.60862 16.6944 3.65099 16.7418 3.70205 16.7785C5.23989 17.8974 7.09277 18.5001 8.99457 18.5001C10.8964 18.5001 12.7493 17.8974 14.2871 16.7785C14.3384 16.7421 14.381 16.6948 14.4119 16.6401C14.4428 16.5854 14.4613 16.5245 14.4661 16.4618C14.4709 16.3991 14.4618 16.3361 14.4395 16.2773C14.4172 16.2186 14.3822 16.1654 14.3371 16.1217V16.1217Z"
        fill="#1F0256"
      />
      <path
        d="M8.99601 12.0662C10.4155 12.0662 11.5662 10.9155 11.5662 9.49601C11.5662 8.07651 10.4155 6.92578 8.99601 6.92578C7.57651 6.92578 6.42578 8.07651 6.42578 9.49601C6.42578 10.9155 7.57651 12.0662 8.99601 12.0662Z"
        fill="#1F0256"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_26688"
          x1="12.3065"
          y1="-0.819108"
          x2="-5.91355"
          y2="17.4024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17CCFC" />
          <stop offset="0.5" stop-color="#5D31FF" />
          <stop offset="1" stop-color="#F35AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
