import * as React from 'react';

export function OpenSourceGradient(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.25 22.7L24.95 20L22.25 17.3M17.75 17.3L15.05 20L17.75 22.7M29 20C29 24.9706 24.9706 29 20 29C15.0294 29 11 24.9706 11 20C11 15.0294 15.0294 11 20 11C24.9706 11 29 15.0294 29 20Z"
        stroke="url(#paint0_linear_749_26699)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_26699"
          x1="13.5"
          y1="11"
          x2="28"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
