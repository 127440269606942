import * as React from 'react';

export function TutorialsGradient(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7 17.7969V23.2069C13.7 23.53 13.7 23.6915 13.7492 23.8341C13.7927 23.9602 13.8637 24.0751 13.957 24.1704C14.0626 24.2782 14.2071 24.3504 14.496 24.4949L19.356 26.9249C19.5921 27.043 19.7102 27.102 19.834 27.1252C19.9437 27.1458 20.0563 27.1458 20.166 27.1252C20.2898 27.102 20.4079 27.043 20.644 26.9249L25.504 24.4949C25.7929 24.3504 25.9374 24.2782 26.043 24.1704C26.1363 24.0751 26.2073 23.9602 26.2508 23.8341C26.3 23.6915 26.3 23.53 26.3 23.2069V17.7969M11 16.4469L19.678 12.1079C19.7961 12.0488 19.8551 12.0193 19.917 12.0077C19.9719 11.9974 20.0281 11.9974 20.083 12.0077C20.1449 12.0193 20.2039 12.0488 20.322 12.1079L29 16.4469L20.322 20.7859C20.2039 20.8449 20.1449 20.8744 20.083 20.8861C20.0281 20.8963 19.9719 20.8963 19.917 20.8861C19.8551 20.8744 19.7961 20.8449 19.678 20.7859L11 16.4469Z"
        stroke="url(#paint0_linear_749_27749)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_27749"
          x1="14.5"
          y1="12"
          x2="29"
          y2="27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
