import { Center, Box, Link, Text, Icon, HStack } from '@chakra-ui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useState } from 'react';

interface Props {
  text: string;
  header?: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  href: string;
  gradient?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

const HoverButton = ({
  text,
  header,
  icon,
  gradient,
  href
}: Props): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Link href={href} target={'_blank'} rel={'noopener noreferrer'}>
      <Center
        role="group"
        display="flex"
        minH={'57px'}
        h="full"
        background="#F7F9FC"
        border="1px solid #E4E9F2"
        cursor="pointer"
        borderRadius="8px"
        justifyContent={'space-between'}
        _hover={{ filter: 'drop-shadow(2px 1px 4px rgba(0, 0, 0, 0.15))' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsHovered(true)}
        onTouchEnd={() => setIsHovered(false)}
        px="24px"
      >
        <HStack>
          <Box
            display="flex"
            rounded="md"
            transition="background .2s"
            justifyContent="center"
            alignItems="center"
          >
            {!isHovered ? (
              <Icon as={icon} boxSize="18px" color="gray.700" pos="relative" />
            ) : (
              <Icon as={gradient} boxSize="18px" pos="relative" />
            )}
          </Box>
          <Box
            display={'flex'}
            flexDir={'column'}
            alignItems={'center'}
            pos="relative"
            left="8px"
          >
            <Text
              w="100%"
              color={isHovered ? 'primary.500' : 'gray.700'}
              fontFamily="body"
              fontSize="14px"
              fontWeight={700}
              zIndex={100}
              whiteSpace="nowrap"
              px="6px"
            >
              {header}
            </Text>

            <Text
              fontSize="14px"
              fontWeight="400"
              whiteSpace={'nowrap'}
              color="gray.500"
              _groupHover={{ color: 'gray.700' }}
              px="6px"
            >
              {text}
            </Text>
          </Box>
        </HStack>

        <Icon as={ChevronRightIcon} _groupHover={{ color: 'primary.500' }} />
      </Center>
    </Link>
  );
};

export default HoverButton;
