import * as React from 'react';

export function PricingIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 4.99975V2.37524C11 1.75144 11 1.43953 10.8686 1.24785C10.7538 1.08039 10.576 0.966621 10.3758 0.932559C10.1467 0.893573 9.86352 1.02428 9.29713 1.28569L2.64426 4.35624C2.13913 4.58938 1.88656 4.70595 1.70158 4.88674C1.53804 5.04656 1.41321 5.24166 1.33663 5.45712C1.25 5.70084 1.25 5.97901 1.25 6.53535V10.2497M11.375 9.87475H11.3825M1.25 7.39975L1.25 12.3497C1.25 13.1898 1.25 13.6099 1.41349 13.9307C1.5573 14.213 1.78677 14.4424 2.06901 14.5863C2.38988 14.7497 2.80992 14.7497 3.65 14.7497H12.35C13.1901 14.7497 13.6101 14.7497 13.931 14.5863C14.2132 14.4424 14.4427 14.213 14.5865 13.9307C14.75 13.6099 14.75 13.1898 14.75 12.3497V7.39975C14.75 6.55967 14.75 6.13963 14.5865 5.81876C14.4427 5.53652 14.2132 5.30705 13.931 5.16324C13.6101 4.99975 13.1901 4.99975 12.35 4.99975L3.65 4.99975C2.80992 4.99975 2.38988 4.99975 2.06901 5.16324C1.78677 5.30705 1.5573 5.53652 1.41349 5.81876C1.25 6.13963 1.25 6.55967 1.25 7.39975ZM11.75 9.87475C11.75 10.0819 11.5821 10.2497 11.375 10.2497C11.1679 10.2497 11 10.0819 11 9.87475C11 9.66764 11.1679 9.49975 11.375 9.49975C11.5821 9.49975 11.75 9.66764 11.75 9.87475Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
