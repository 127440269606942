import {
  Input,
  useToast,
  Textarea,
  Button,
  FormLabel,
  Text,
  FormControl,
  Flex,
  HStack,
  List,
  ListIcon,
  ListItem
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { useUser } from '@clerk/nextjs';
import { useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/outline';
import { CloseIcon } from './icons/closeIcon';

export function ContactForm(props: { onSubmit: () => void }) {
  const [mediaState, setMediaState] = useState<File[]>([]);
  const { user } = useUser();
  const toast = useToast();
  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    formState,
    reset
  } = useForm({
    defaultValues: {
      name: user?.fullName || '',
      email: user?.primaryEmailAddress?.emailAddress || '',
      message: '',
      media: [] as Array<File>
    }
  });
  // create a way to remove media from the file list
  const removeMedia = (index: number) => {
    const files = getValues('media');
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setValue('media', newFiles);
    setMediaState([...newFiles]);
  };

  const isEmailValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  async function onSubmit({
    name,
    email,
    message,
    media
  }: {
    name: string;
    email: string;
    message: string;
    media: Array<File>;
  }) {
    // maximum total file size is 6MB
    const maxFilesSize = 1000 * 1000 * 6; // 6MB
    // maximum number of files is 25
    const maxFiles = 25;
    let totalSize = 0;
    if (media.length > maxFiles) {
      toast({
        title: 'Error submitting',
        description: `Sorry, the maximum number of files is ${maxFiles}. Please reduce the number of files and try again`,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      return;
    }
    const data = new FormData();

    data.set('name', name);
    data.set('email', email);
    data.set('message', message);
    data.set('customerId', user?.id || '');
    for (const file of media) {
      totalSize += file.size;
      if (totalSize > maxFilesSize) {
        toast({
          title: 'Error submitting',
          description:
            'Sorry, the maximum total file size is 6MB. Please reduce the size of your files and try again',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
        return;
      }
      data.append('files', file, file.name);
    }

    try {
      const result = await fetch('/api/contact-form/', {
        method: 'POST',
        body: data
      });
      if (result.ok) {
        reset();
        props.onSubmit();
        toast({
          title: 'Submitted successfully',
          variant: 'solid',
          status: 'success',
          description:
            'Support will respond to your message via email as soon as possible.',
          duration: 10000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Error submitting',
          description:
            'Sorry, there was an error submitting your message. Please try again.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error occurred',
        description:
          'Sorry, there was an error submitting your message. Please try again or reach out through our Discord Support.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }
  const handleChangeUpdate = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (!input.files) return;
    setMediaState([...getValues('media')]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap="16px">
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Your name</FormLabel>
              <Input
                {...field}
                type="text"
                variant="outline"
                borderColor="gray.400"
                textColor="black"
                fontWeight="medium"
                _placeholder={{ opacity: 0.4, color: 'inherit' }}
                focusBorderColor="gray.200"
                placeholder="John Smith"
              />
            </FormControl>
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{ required: true, validate: value => isEmailValid(value) }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Your email</FormLabel>
              <Input
                type="email"
                {...field}
                borderColor="gray.400"
                focusBorderColor="gray.200"
                textColor="black"
                fontWeight="medium"
                _placeholder={{ opacity: 0.4, color: 'inherit' }}
                placeholder="john.smith@clerk.com"
              />
            </FormControl>
          )}
        />
        <Controller
          name="message"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Your message</FormLabel>
              <Textarea
                {...field}
                borderColor="gray.400"
                focusBorderColor="gray.200"
                textColor="black"
                fontWeight="medium"
                _placeholder={{ opacity: 0.4, color: 'inherit' }}
                placeholder="Hi there, I need help with..."
              />
            </FormControl>
          )}
        />

        <FormControl>
          <Input
            hidden
            id="mediaInput"
            {...register('media', {
              required: false,
              onChange(event) {
                handleChangeUpdate(event);
              }
            })}
            type="file"
            multiple
            accept="video/*, image/*,.pdf"
          />
          <HStack
            cursor="pointer"
            onClick={() => {
              {
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              }
              document.getElementById('mediaInput')!.click();
            }}
          >
            <PaperClipIcon width="18px" />
            <Text>Attach files</Text>
          </HStack>
          {mediaState.length > 0 && (
            <List mt="3" spacing={3}>
              {[...mediaState].map((file: File, index: number) => (
                <ListItem
                  fontSize="sm"
                  borderRadius="md"
                  padding="1"
                  _hover={{ bg: '#ebedf0' }}
                  cursor="pointer"
                  key={index}
                  onClick={() => removeMedia(index)}
                >
                  <ListIcon fontSize="18px" as={CloseIcon} color="gray.600" />
                  {file.name.substring(0, 20)}
                </ListItem>
              ))}
            </List>
          )}
        </FormControl>
        <Button
          isLoading={formState.isSubmitting}
          disabled={!formState.isValid || formState.isSubmitting}
          marginBottom="0"
          size="md"
          minH="36px"
          color="white"
          type="submit"
          aria-label="Send"
        >
          Send
        </Button>
      </Flex>
    </form>
  );
}
