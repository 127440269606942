import * as React from 'react';

export function MapIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_749_27702)">
        <path
          d="M6.75 13.5l-5.25 3v-12l5.25-3m0 12l5.25 3m-5.25-3v-12m5.25 15l4.5-3v-12l-4.5 3m0 12v-12m0 0l-5.25-3"
          stroke="currentColor"
          strokeWidth={1.25}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_749_27702">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
