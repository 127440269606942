import * as React from 'react';

export function SAMLIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 6.74996C12.75 6.36609 12.6035 5.98222 12.3107 5.68934C12.0178 5.39645 11.6339 5.25 11.25 5.25M11.25 11.25C13.7353 11.25 15.75 9.23528 15.75 6.75C15.75 4.26472 13.7353 2.25 11.25 2.25C8.76472 2.25 6.75 4.26472 6.75 6.75C6.75 6.95526 6.76374 7.15731 6.79036 7.35528C6.83413 7.68089 6.85602 7.84369 6.84129 7.94669C6.82594 8.05399 6.8064 8.11181 6.75352 8.20642C6.70275 8.29725 6.61328 8.38672 6.43435 8.56565L2.60147 12.3985C2.47176 12.5282 2.4069 12.5931 2.36052 12.6688C2.3194 12.7359 2.2891 12.809 2.27072 12.8856C2.25 12.9719 2.25 13.0636 2.25 13.2471V14.55C2.25 14.97 2.25 15.1801 2.33175 15.3405C2.40365 15.4816 2.51839 15.5963 2.65951 15.6683C2.81994 15.75 3.02996 15.75 3.45 15.75H5.25V14.25H6.75V12.75H8.25L9.43435 11.5657C9.61328 11.3867 9.70275 11.2973 9.79358 11.2465C9.88819 11.1936 9.94601 11.1741 10.0533 11.1587C10.1563 11.144 10.3191 11.1659 10.6447 11.2096C10.8427 11.2363 11.0447 11.25 11.25 11.25Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
