import {
  Box,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  VisuallyHidden
} from '@chakra-ui/react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import * as React from 'react';
import { URLS } from '@lib';
import {
  DiscordIcon,
  FacebookIcon,
  GitHubIcon,
  LinkedInIcon,
  TwitterIcon
} from '../common/icons';
import { LayoutContainer } from '@components';

import { useRouter } from 'next/router';

const {
  blog,
  pricing,
  company,
  dashboard,
  careers,
  docs,
  support,
  terms,
  privacy,
  facebook,
  twitter,
  linkedin,
  github,
  discord,
  apiStatus,
  mediaKit,
  roadmap,
  components,
  changelog
} = URLS;

type FooterNavigationKey =
  | 'product'
  | 'developers'
  | 'company'
  | 'legal'
  | string;

type FooterNavItem =
  | {
      name: string;
      href: string;
      onClick?: never;
    }
  | {
      name: string;
      href?: never;
      onClick: React.MouseEventHandler;
    };

type FooterNavigation = {
  [key in FooterNavigationKey]: FooterNavItem[];
};

const baseFooterNavigation = (): FooterNavigation => {
  return {
    product: [
      { name: 'Components', href: components },
      { name: 'Pricing', href: pricing },
      { name: 'Dashboard', href: dashboard },
      { name: 'Feature Requests', href: roadmap }
    ],
    developers: [
      { name: 'Documentation', href: docs },
      { name: 'Discord server', href: discord },
      { name: 'Support', href: support },
      { name: 'API status', href: apiStatus },
      { name: 'Changelog', href: changelog }
    ],
    company: [
      { name: 'About', href: company },
      { name: 'Careers', href: careers },
      { name: 'Blog', href: blog },
      { name: 'Media kit', href: mediaKit }
    ],
    legal: [
      { name: 'Terms', href: terms },
      { name: 'Privacy', href: privacy }
    ]
  };
};

const appendCookieConsentIfEu = (data: FooterNavigation): FooterNavigation => {
  if (typeof window !== 'undefined') {
    const consentEntry = {
      name: 'Consent Manager',
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        if (typeof (window as any).__openSegmentDialog === 'function') {
          (window as any).__openSegmentDialog();
        }
      }
    };
    data.legal.push(consentEntry);
  }
  return data;
};

const useFooterNavigation = () => {
  const [data, setData] = React.useState(baseFooterNavigation);
  // inEU can only run in a browser env as it depends on window.navigator
  // we're showing the list on the server and on client,
  // but we're only appending the cookie consent *after* the first render
  // in order to avoid hydration errors
  React.useEffect(() => {
    setData(d => appendCookieConsentIfEu(d));
  }, []);
  return data;
};

type IconProps = {
  height: string;
  width: string;
  'aria-hidden': string;
};

const social = [
  {
    name: 'Twitter',
    href: twitter,
    icon: (props: IconProps) => <TwitterIcon {...props} />
  },
  {
    name: 'LinkedIn',
    href: linkedin,
    icon: (props: IconProps) => (
      <LinkedInIcon {...props} height="20px" width="20px" />
    )
  },
  {
    name: 'GitHub',
    href: github,
    icon: (props: IconProps) => <GitHubIcon {...props} />
  },
  {
    name: 'Discord',
    href: discord,
    icon: (props: IconProps) => <DiscordIcon {...props} />
  },
  {
    name: 'Facebook',
    href: facebook,
    icon: (props: IconProps) => <FacebookIcon {...props} />
  }
] as const;

function Copyright(): JSX.Element {
  return (
    <Text textStyle="paragraph-sm" color="gray.500">
      &copy; {new Date().getFullYear()} Clerk Inc.
    </Text>
  );
}

export function Footer(): JSX.Element {
  const footerNavigation = useFooterNavigation();
  const dividerOrientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'horizontal',
    lg: 'vertical'
  });
  const { pathname } = useRouter();

  const whiteFooters = ['/pricing', '/components', '/customers'];

  return (
    <LayoutContainer
      as="footer"
      role="contentinfo"
      bg={whiteFooters.includes(pathname) ? 'white' : 'none'}
      verticalPadding="none"
    >
      <Stack
        spacing={{ lg: '8' }}
        justifyContent="center"
        py={{ base: '0', lg: '16' }}
        // mt="44px"
      >
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Center>
            <Stack spacing={{ base: '6', lg: '8' }} align="start">
              <HStack marginBottom={3}>
                <Image
                  src="/images/footer-logo.svg"
                  alt="Clerk logo"
                  width={32}
                  height={32}
                />

                <Text textStyle="label-sm" color="gray.700" pl="24px">
                  Clerk - Complete User Management
                </Text>
              </HStack>

              <ButtonGroup
                variant="ghost"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop={4}
                minWidth={56}
              >
                {social.map(item => (
                  <Box
                    as="a"
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    rel="noopener"
                    color="gray.400"
                    transition="color .2s"
                    _hover={{ color: 'gray.700' }}
                  >
                    <VisuallyHidden>{item.name}</VisuallyHidden>
                    {item.icon({
                      height: '22px',
                      width: '22px',
                      'aria-hidden': 'true'
                    })}
                  </Box>
                ))}
              </ButtonGroup>

              <Box pt="8" pb="12" display={{ base: 'none', lg: 'block' }}>
                <Copyright />
              </Box>
            </Stack>
          </Center>

          <Center flex={1} py={{ base: 12, lg: 0 }} px={{ lg: 5 }}>
            <Divider orientation={dividerOrientation} />
          </Center>
          <Center>
            <Grid
              templateColumns={{
                base: 'repeat(2, min-content)',
                md: 'repeat(4, 1fr)'
              }}
              gap={12}
              overflowX="hidden"
            >
              {Object.keys(footerNavigation).map(
                (item: FooterNavigationKey) => {
                  return (
                    <GridItem
                      minW={{ base: 32, sm: 36, lg: 28, xl: 40 }}
                      key={item}
                    >
                      <Heading
                        size="sm"
                        as="span"
                        display="block"
                        color="gray.500"
                        paddingBottom={8}
                        whiteSpace="nowrap"
                      >
                        {item}
                      </Heading>

                      <Stack spacing={4} shouldWrapChildren>
                        {footerNavigation[item].map(el => (
                          <Box key={el.name}>
                            <Link legacyBehavior href={el.href || '#'} passHref>
                              <Text
                                onClick={el.onClick}
                                as="a"
                                color="gray.700"
                                textStyle="label-sm"
                                cursor="pointer"
                                whiteSpace="nowrap"
                                _hover={{
                                  color: 'primary.500'
                                }}
                              >
                                {el.name}
                              </Text>
                            </Link>
                          </Box>
                        ))}
                      </Stack>
                    </GridItem>
                  );
                }
              )}
            </Grid>
          </Center>
        </Flex>

        <Center pt="8" pb="12" display={{ base: 'flex', lg: 'none' }} mx="auto">
          <Copyright />
        </Center>
      </Stack>
      <Divider />
    </LayoutContainer>
  );
}
