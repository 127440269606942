import React from 'react';
import { ConsentManagerBuilder } from '@segment/consent-manager';
import { Container } from './Container';
import inEU from '@segment/in-eu';
import { DestinationPreferences } from './categories';
import Script from 'next/script';

const writeKey = `${process.env.NEXT_PUBLIC_SEGMENT}`;

export function CookiesConsent() {
  const [forceRenderProp, setForceRenderProp] = React.useState(0);
  const shouldRenderRef = React.useRef(true);

  React.useEffect(() => {
    const id = setInterval(() => {
      if (shouldRenderRef.current) {
        setForceRenderProp(k => k + 1);
      } else {
        clearInterval(id);
      }
    }, 500);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <>
      {/*
        The @segment/consent-manager package doesn't work out of the box with @segment/analytics-next.

        To fix the issue, we modified the analytics.load function so that it is triggered by
        the consent-manager package, but on the analytics instance that is generated by the segment/analytics-next package.

        That way, we can also pass all the custom settings to the load function of the latter.

        https://github.com/segmentio/consent-manager/issues/193

      */}
      <Script
        id="show-banner"
        dangerouslySetInnerHTML={{
          __html: `
          !(function () {
            console.log("Analytics", window.analytics);
            var analytics = (window.analytics = window.analytics || []);
            if (!analytics.initialize)
              if (analytics.invoked)
                window.console &&
                  console.error &&
                  console.error("Segment snippet included twice.");
              else {
                analytics.invoked = !0;
                analytics.methods = [
                  "trackSubmit",
                  "trackClick",
                  "trackLink",
                  "trackForm",
                  "pageview",
                  "identify",
                  "reset",
                  "group",
                  "track",
                  "ready",
                  "alias",
                  "debug",
                  "page",
                  "once",
                  "off",
                  "on",
                  "addSourceMiddleware",
                  "addIntegrationMiddleware",
                  "setAnonymousId",
                  "addDestinationMiddleware",
                ];
                analytics.factory = function (e) {
                  return function () {
                    var t = Array.prototype.slice.call(arguments);
                    t.unshift(e);
                    analytics.push(t);
                    return analytics;
                  };
                };
                for (var e = 0; e < analytics.methods.length; e++) {
                  var key = analytics.methods[e];
                  analytics[key] = analytics.factory(key);
                }

                analytics.load = function (key, e) {
                  window.__analyticsBrowser.load(
                    {
                      writeKey: '${process.env.NEXT_PUBLIC_SEGMENT}',
                      cdnURL: 'https://scdn.clerk.com'
                    },
                    {
                      integrations: {
                        'Segment.io': {
                          // POST https://MY-CUSTOM-API-PROXY.com/v1/t --> proxies to
                          // https://api.segment.io/v1/t
                          apiHost: 'segapi.clerk.com/v1',
                          protocol: 'https' // optional
                        }
                      }
                    }
                  );

                  // HACK: This is to enforce a full page reload when the new consent preferences are less than the previous setup.
                  // For more information refer to https://github.com/segmentio/consent-manager/blob/master/src/consent-manager-builder/analytics.ts#L78
                  window.analytics.initialized = true;
                };
                analytics.SNIPPET_VERSION = "4.13.2";
                analytics.page();
              }
          })();
          `
        }}
      />
      <ConsentManagerBuilder
        writeKey={writeKey}
        shouldRequireConsent={inEU}
        // @ts-ignore
        // The internal isLoading variable of ConsentManagerBuilder sometimes does not switch to true
        // (https://github.com/segmentio/consent-manager/blob/master/src/consent-manager-builder/index.tsx#L187)
        // so our UI is not rendered.
        // This seems to be an issue of the segment library.
        // We're using this randomProp to manually trigger a rerender as a workaround.
        randomProp={forceRenderProp}
      >
        {({
          destinations,
          preferences,
          saveConsent,
          isConsentRequired,
          destinationPreferences,
          newDestinations
        }) => {
          shouldRenderRef.current = false;

          const savePreferences = (preferences: DestinationPreferences) => {
            return saveConsent(preferences);
          };

          const setAllPreferencesTo = (val: boolean) => {
            const res = { ...preferences };

            for (const destination of destinations) {
              res[destination.id] = res[destination.id] ?? false;
              const value = res[destination.id];
              res[destination.id] = typeof value === 'string' ? value : val;
            }
            return savePreferences(res);
          };

          const onAcceptAll = () => setAllPreferencesTo(true);
          const onDenyAll = () => setAllPreferencesTo(false);

          return (
            <Container
              shouldShowBanner={isConsentRequired && newDestinations.length > 0}
              isConsentRequired={isConsentRequired}
              onAcceptAll={onAcceptAll}
              onDenyAll={onDenyAll}
              destinations={destinations}
              destinationPreferences={destinationPreferences}
              savePreferences={savePreferences}
            />
          );
        }}
      </ConsentManagerBuilder>
    </>
  );
}
