export function CustomFlowsIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 6.75H1.5M10.5 13.125L12.375 11.25L10.5 9.375M7.5 9.375L5.625 11.25L7.5 13.125M1.5 5.85L1.5 12.15C1.5 13.4101 1.5 14.0402 1.74524 14.5215C1.96095 14.9448 2.30516 15.289 2.72852 15.5048C3.20982 15.75 3.83988 15.75 5.1 15.75H12.9C14.1601 15.75 14.7902 15.75 15.2715 15.5048C15.6948 15.289 16.0391 14.9448 16.2548 14.5215C16.5 14.0402 16.5 13.4101 16.5 12.15V5.85C16.5 4.58988 16.5 3.95982 16.2548 3.47852C16.0391 3.05516 15.6948 2.71095 15.2715 2.49524C14.7902 2.25 14.1601 2.25 12.9 2.25L5.1 2.25C3.83988 2.25 3.20982 2.25 2.72852 2.49524C2.30516 2.71095 1.96095 3.05516 1.74524 3.47852C1.5 3.95982 1.5 4.58988 1.5 5.85Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_27880"
          x1="2"
          y1="2"
          x2="17"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
