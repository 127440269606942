import * as React from 'react';

export function EmailSMSGradient(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 18.875H16.6325M20 18.875H20.0075M23.375 18.875H23.3825M16.25 24.5V26.2516C16.25 26.6513 16.25 26.8511 16.3319 26.9537C16.4032 27.043 16.5112 27.0949 16.6254 27.0948C16.7567 27.0946 16.9128 26.9698 17.2248 26.7201L19.0139 25.2889C19.3794 24.9965 19.5621 24.8503 19.7656 24.7463C19.9461 24.6541 20.1383 24.5867 20.3369 24.5459C20.5608 24.5 20.7948 24.5 21.2628 24.5H23.15C24.4101 24.5 25.0402 24.5 25.5215 24.2548C25.9448 24.039 26.289 23.6948 26.5048 23.2715C26.75 22.7902 26.75 22.1601 26.75 20.9V16.85C26.75 15.5899 26.75 14.9598 26.5048 14.4785C26.289 14.0552 25.9448 13.711 25.5215 13.4952C25.0402 13.25 24.4101 13.25 23.15 13.25H16.85C15.5899 13.25 14.9598 13.25 14.4785 13.4952C14.0552 13.711 13.711 14.0552 13.4952 14.4785C13.25 14.9598 13.25 15.5899 13.25 16.85V21.5C13.25 22.1975 13.25 22.5462 13.3267 22.8323C13.5347 23.6088 14.1412 24.2153 14.9177 24.4233C15.2038 24.5 15.5525 24.5 16.25 24.5ZM17 18.875C17 19.0821 16.8321 19.25 16.625 19.25C16.4179 19.25 16.25 19.0821 16.25 18.875C16.25 18.6679 16.4179 18.5 16.625 18.5C16.8321 18.5 17 18.6679 17 18.875ZM20.375 18.875C20.375 19.0821 20.2071 19.25 20 19.25C19.7929 19.25 19.625 19.0821 19.625 18.875C19.625 18.6679 19.7929 18.5 20 18.5C20.2071 18.5 20.375 18.6679 20.375 18.875ZM23.75 18.875C23.75 19.0821 23.5821 19.25 23.375 19.25C23.1679 19.25 23 19.0821 23 18.875C23 18.6679 23.1679 18.5 23.375 18.5C23.5821 18.5 23.75 18.6679 23.75 18.875Z"
        stroke="url(#paint0_linear_749_26892)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_26892"
          x1="13"
          y1="13"
          x2="27"
          y2="27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
