import { SVGProps } from 'react';

export function RemixLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0725 11.9056C12.1968 13.5694 12.1968 14.3494 12.1968 15.2008H8.50428C8.50428 15.0154 8.50773 14.8456 8.51061 14.674C8.52097 14.1388 8.53133 13.5814 8.44789 12.4552C8.33856 10.807 7.65725 10.4404 6.40511 10.4404H0.600185V7.44038H6.58235C8.16363 7.44038 8.95427 6.93938 8.95427 5.61098C8.95427 4.44338 8.16363 3.73598 6.58235 3.73598H0.599609V0.800781H7.24064C10.8204 0.800781 12.5996 2.56358 12.5996 5.37998C12.5996 7.48658 11.3475 8.85998 9.65687 9.08918C11.0839 9.38738 11.9183 10.2346 12.0725 11.9056ZM0.599609 15.2008V12.9646H4.50332C5.15528 12.9646 5.29684 13.4686 5.29684 13.7698V15.2008H0.599609Z"
        fill="black"
      />
    </svg>
  );
}
