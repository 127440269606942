import { SVGProps } from 'react';

export function FaunaLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.63281 1.125L10.5275 7.41994L11.8933 5.66381L15.3656 4.96181L13.6117 7.84913V11.5554L4.1285 16.875L6.62544 12.8042L8.99919 11.2826L6.62544 11.8288L4.40188 8.20013L8.77419 9.33188L3.89731 6.95137L2.63281 1.125Z"
        fill="#323FCB"
      />
    </svg>
  );
}
