import * as React from 'react';

export function FirebaseLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_749_27527)">
        <path
          d="M11.036 6.503L9.234 8.18 7.563 4.807l.865-1.94c.225-.393.576-.388.795 0l1.813 3.636z"
          fill="#FFA000"
        />
        <path
          d="M7.563 4.805l1.672 3.372-6.733 6.263 5.061-9.635z"
          fill="#F57F17"
        />
        <path
          d="M13.007 3.94c.322-.31.655-.204.74.234L15.5 14.358l-5.81 3.487c-.202.113-.742.161-.742.161s-.492-.058-.68-.169l-5.766-3.396L13.007 3.94z"
          fill="#FFCA28"
        />
        <path
          d="M7.563 4.805l-5.061 9.636L4.756.359c.083-.439.333-.481.556-.094l2.25 4.54z"
          fill="#FFA000"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_749_27527">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
