import * as React from 'react';

export function HasuraLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_749_27498)">
        <path
          d="M1.592.001a.294.294 0 00-.251.104C.337 1.294.026 4.515.544 6.129c.17.536.22 1.102.117 1.655-.1.547-.202 1.21-.202 1.667C.46 14.171 4.285 18 9.001 18c4.718 0 8.542-3.827 8.542-8.549 0-.46-.1-1.12-.203-1.667a3.406 3.406 0 01.118-1.655c.518-1.613.207-4.835-.797-6.024a.304.304 0 00-.486.037l-1.237 1.943a.95.95 0 01-1.27.206A8.494 8.494 0 009.001.903 8.494 8.494 0 004.334 2.29a.953.953 0 01-1.27-.206L1.827.142a.307.307 0 00-.235-.141zm7.409 2.852a6.627 6.627 0 016.614 6.618c-.002 3.649-2.968 6.618-6.614 6.618S2.389 13.12 2.389 9.47A6.627 6.627 0 016.175 3.49 6.548 6.548 0 019 2.853zM7.176 6.55a.19.19 0 00-.165.096.19.19 0 00.003.19L8.399 9.17l-1.86 2.84a.193.193 0 00.06.268c.03.018.063.027.097.027H8.09a.193.193 0 00.16-.086l1.006-1.574.902 1.565a.19.19 0 00.164.095h1.374a.185.185 0 00.164-.095c.04-.06.04-.131.005-.191l-1.69-2.931-1.45-2.445a.188.188 0 00-.164-.093H7.176z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_749_27498">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
