import * as React from 'react';

export function SupportIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M8.995 3.852c-1.5-1.753-4-2.225-5.879-.62C1.237 4.839.973 7.523 2.448 9.42c1.227 1.579 4.94 4.908 6.156 5.986.136.12.204.18.284.204.069.02.145.02.214 0 .08-.024.148-.084.284-.204 1.216-1.078 4.929-4.407 6.156-5.986 1.475-1.898 1.243-4.6-.668-6.187-1.911-1.589-4.38-1.134-5.88.619z"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
