import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Product } from './product/product';
import { Developers } from './developers/developers';
import { Company } from './company/company';
import styles from './styles.module.css';
import {
  ProductSections,
  DevelopersSections
} from '@components/layout/header/content';
import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';

const RadixMenu = ({ textColor }) => {
  const [value, setValue] = React.useState('');

  const { asPath } = useRouter();

  React.useEffect(() => {
    setValue('');
  }, [asPath]);

  return (
    <NavigationMenu.Root
      className={styles.NavigationMenuRoot}
      delayDuration={0}
      skipDelayDuration={0}
      value={value}
      onValueChange={setValue}
    >
      <NavigationMenu.List className={styles.NavigationMenuList}>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className={styles.NavigationMenuTrigger}>
            <Box color={textColor}>Product</Box>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className={styles.NavigationMenuContent}>
            <Product sections={ProductSections} />
          </NavigationMenu.Content>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className={styles.NavigationMenuTrigger}>
            <Box color={textColor}> Developers </Box>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className={styles.NavigationMenuContent}>
            <Developers sections={DevelopersSections} />
          </NavigationMenu.Content>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className={styles.NavigationMenuTrigger}>
            <Box color={textColor}> Company</Box>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className={styles.NavigationMenuContent}>
            <Company />
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link
            className={styles.NavigationMenuLink}
            href="/startups"
          >
            <Box color={textColor}> For&nbsp;Startups </Box>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link
            className={styles.NavigationMenuLink}
            href="/pricing"
          >
            <Box color={textColor}> Pricing </Box>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link
            className={styles.NavigationMenuLink}
            href="/docs"
          >
            <Box color={textColor}> Documentation</Box>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Indicator className={styles.NavigationMenuIndicator}>
          <div className={styles.Arrow} />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className={styles.ViewportPosition}>
        <NavigationMenu.Viewport className={styles.NavigationMenuViewport} />
      </div>
    </NavigationMenu.Root>
  );
};

export default RadixMenu;
