import React, { SVGProps } from 'react';

export function GoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="15"
      viewBox="0 0 39 15"
      fill="#00ACD7"
      {...props}
    >
      <path d="M2.885 4.542c-.075 0-.093-.04-.056-.098l.39-.528c.038-.058.131-.097.205-.097h6.645c.074 0 .093.058.055.117l-.316.508c-.037.059-.13.117-.186.117l-6.737-.02ZM.074 6.34C0 6.34-.019 6.301.02 6.243l.39-.528c.038-.059.13-.098.205-.098h8.487c.074 0 .112.059.093.117l-.149.47c-.019.078-.093.117-.167.117l-8.804.02ZM4.578 8.139c-.074 0-.093-.059-.055-.118l.26-.488c.037-.059.112-.118.186-.118h3.723c.074 0 .111.06.111.137l-.037.47a.143.143 0 0 1-.13.136l-4.058-.02ZM23.897 4.19c-1.172.313-1.973.547-3.127.86-.279.078-.297.098-.54-.195-.279-.333-.483-.548-.874-.743-1.173-.606-2.308-.43-3.369.293-1.265.86-1.917 2.13-1.898 3.714.018 1.564 1.042 2.854 2.512 3.07 1.266.175 2.327-.294 3.164-1.291.168-.215.317-.45.503-.723h-3.592c-.391 0-.484-.254-.354-.587.242-.606.689-1.622.95-2.13.055-.118.186-.313.465-.313h6.774c-.037.528-.037 1.055-.111 1.583-.205 1.408-.708 2.698-1.527 3.832-1.34 1.857-3.09 3.01-5.304 3.323-1.824.254-3.517-.118-5.006-1.29-1.378-1.095-2.16-2.542-2.364-4.34-.242-2.13.354-4.047 1.582-5.728 1.321-1.818 3.07-2.97 5.211-3.381 1.75-.333 3.425-.118 4.932.957.987.685 1.694 1.623 2.16 2.757.11.176.036.273-.187.332Z" />
      <path d="M30.057 15c-1.693-.04-3.238-.547-4.54-1.72a6.215 6.215 0 0 1-2.01-3.773c-.336-2.209.241-4.164 1.507-5.903C26.372 1.727 28.01.75 30.224.339c1.9-.352 3.686-.156 5.305.997 1.47 1.056 2.382 2.483 2.624 4.36.317 2.638-.41 4.788-2.14 6.626-1.228 1.31-2.736 2.13-4.467 2.502-.502.098-1.005.117-1.489.176Zm4.43-7.897c-.019-.255-.019-.45-.056-.645-.335-1.936-2.029-3.03-3.797-2.6-1.73.41-2.847 1.563-3.257 3.401-.335 1.525.373 3.069 1.713 3.695 1.023.469 2.047.41 3.033-.118 1.47-.801 2.27-2.052 2.364-3.733Z" />
    </svg>
  );
}
export default GoIcon;
