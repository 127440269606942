import { SVGProps } from 'react';

export function ExpoLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_749_27856)">
        <path
          d="M8.05568 5.5145C8.19466 5.31352 8.34687 5.28799 8.47032 5.28799C8.59383 5.28799 8.79949 5.31352 8.93855 5.5145C10.0344 6.99074 11.8432 9.93136 13.1776 12.1005C14.0477 13.5151 14.7161 14.6016 14.8532 14.7399C15.3679 15.2589 16.0738 14.9355 16.4841 14.3467C16.8879 13.7671 17 13.36 17 12.9258C17 12.6302 11.1493 1.95844 10.5601 1.07017C9.99348 0.215831 9.80887 0 8.839 0H8.11332C7.14638 0 7.00659 0.215831 6.43988 1.07017C5.85079 1.9585 0 12.6301 0 12.9259C0 13.36 0.112094 13.7671 0.515977 14.3467C0.926168 14.9355 1.63207 15.2589 2.14685 14.7399C2.28398 14.6016 2.95229 13.5152 3.82248 12.1005C5.15678 9.93136 6.95984 6.99067 8.05568 5.5145Z"
          fill="#000020"
        />
      </g>
      <defs>
        <clipPath id="clip0_749_27856">
          <rect width="17" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
