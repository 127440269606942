import { AspectRatio, Center } from '@chakra-ui/react';
import { GridPatternDark, LayoutContainer, Pricing } from '@components/common';
import { StartNow } from './start-now';

export function StartNowPricing(): JSX.Element {
  return (
    <LayoutContainer verticalPadding="sm">
      <Center
        pos="relative"
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="space-around"
        alignItems={'center'}
        left={{ base: '0', lg: '7%' }}
      >
        <AspectRatio
          ratio={1400 / 1190}
          w="1196px"
          h="962px"
          pos="absolute"
          top="-46px"
          left="258px"
          pointerEvents="none"
          draggable={false}
          userSelect="none"
        >
          <GridPatternDark />
        </AspectRatio>
        <StartNow />
        <Pricing />
      </Center>
    </LayoutContainer>
  );
}
