import * as React from 'react';

export function APISDKIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 8.25H6m1.5 3H6m6-6H6m9 2.625V5.1c0-1.26 0-1.89-.245-2.371a2.25 2.25 0 00-.984-.984c-.48-.245-1.11-.245-2.371-.245H6.6c-1.26 0-1.89 0-2.371.245a2.25 2.25 0 00-.984.984C3 3.209 3 3.839 3 5.1v7.8c0 1.26 0 1.89.245 2.371.216.424.56.768.984.984.48.245 1.11.245 2.371.245h2.025m7.875 0l-1.125-1.125m.75-1.875a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
