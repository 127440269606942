const MARKETING_AND_ANALYTICS_CATEGORIES = [
  'A/B Testing',
  'Analytics',
  'Attribution',
  'Email',
  'Enrichment',
  'Heatmaps & Recordings',
  'Raw Data',
  'Realtime Dashboards',
  'Referrals',
  'Surveys',
  'Video'
];

const ADVERTISING_CATEGORIES = ['Advertising', 'Tag Managers'] as const;

const FUNCTIONAL_CATEGORIES = [
  'CRM',
  'Customer Success',
  'Deep Linking',
  'Helpdesk',
  'Livechat',
  'Performance Monitoring',
  'Personalization',
  'SMS & Push Notifications',
  'Security & Fraud'
];

export const GroupToCategoryMap = {
  // functional: FUNCTIONAL_CATEGORIES,
  marketing: MARKETING_AND_ANALYTICS_CATEGORIES,
  advertising: ADVERTISING_CATEGORIES,
  essential: []
};

export type Group = keyof typeof GroupToCategoryMap;

export type Category =
  | (typeof MARKETING_AND_ANALYTICS_CATEGORIES)[number]
  | (typeof ADVERTISING_CATEGORIES)[number];
// | (typeof FUNCTIONAL_CATEGORIES)[number];

export interface Destination {
  id: string;
  name: string;
  creationName: string;
  description: string;
  website: string;
  category: Category | string;
}

export type DestinationPreferences = Record<
  string,
  string | boolean | null | undefined
>;

const destinationToCategory = (destination: Destination) => {
  return destination.category;
};

const availableDestinationsToAcceptedCategories = (
  destinations: Destination[],
  destinationPreferences: DestinationPreferences = {}
) => {
  const acceptedDestinationIds: string[] = [];

  for (const [destinationId, isAccepted] of Object.entries(
    destinationPreferences
  )) {
    if (isAccepted) {
      acceptedDestinationIds.push(destinationId);
    }
  }
  const acceptedDestinations = destinations.filter(dest =>
    acceptedDestinationIds.includes(dest.id)
  );
  const acceptedCategories = [
    ...new Set(acceptedDestinations.map(destinationToCategory))
  ];
  return acceptedCategories;
};

export const isGroupEnabled = (
  key: Group,
  destinations: Destination[],
  destinationPreferences: DestinationPreferences
): boolean => {
  const acceptedCategories = availableDestinationsToAcceptedCategories(
    destinations,
    destinationPreferences
  );
  // if a group has no defined categories it means that its always enabled
  // in our case, this is true for the essential cookies that cannot be disabled
  if (GroupToCategoryMap[key].length === 0) {
    return true;
  }
  return GroupToCategoryMap[key].some(c => acceptedCategories.includes(c));
};

export const destinationPreferencesFromGroups = (
  groups: Record<string, boolean>,
  destinations: Destination[]
): DestinationPreferences => {
  const res = {} as DestinationPreferences;
  for (const [key, isAccepted] of Object.entries(groups)) {
    const destinationsForGroup = destinations.filter(d =>
      // @ts-expect-error
      GroupToCategoryMap[key as Group].includes(d.category)
    );
    destinationsForGroup.forEach(d => (res[d.id] = isAccepted));
  }

  return res;
};
