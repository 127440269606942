import { createContext, useContext, useState } from 'react';

const Context = createContext();

export function BannerProvider({ children }) {
  const [showBanner, setShowBanner] = useState(true);
  return (
    <Context.Provider value={[showBanner, setShowBanner]}>
      {children}
    </Context.Provider>
  );
}

export function useBannerContext() {
  return useContext(Context);
}
