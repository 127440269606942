import {
  Flex,
  Stack,
  Box,
  Text,
  Center,
  HStack,
  Icon,
  Link
} from '@chakra-ui/react';
import { DevelopersSections } from '../content';
import { Header } from './header';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { DocumentationIcon } from '../icons';
import { DocumentationGradient } from './gradients';

interface Props {
  setSelected?: (selected: string | null) => void;
  onClose?: () => void;
  title: string;
}

export function DocsButton() {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Link href="/docs" target={'_blank'} rel={'noopener noreferrer'}>
      <Center
        role="group"
        display="flex"
        minH={'57px'}
        h="57px"
        border="1px solid #E4E9F2"
        cursor="pointer"
        borderRadius="8px"
        bg="gray.50"
        justifyContent={'space-between'}
        px="14px"
        py="16px"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsHovered(true)}
        onTouchCancel={() => setIsHovered(false)}
        onClick={() => setIsHovered(false)}
      >
        <HStack>
          <Box
            display="flex"
            rounded="md"
            transition="background .2s"
            justifyContent="center"
            alignItems="center"
            pl="8px"
          >
            {!isHovered ? (
              <Icon as={DocumentationIcon} boxSize="18px" color={'gray.700'} />
            ) : (
              <Icon as={DocumentationGradient} boxSize="18px" />
            )}
          </Box>
          <Box
            display={'flex'}
            flexDir={'column'}
            alignItems={'center'}
            pos="relative"
            left="8px"
          >
            <Text
              w="100%"
              fontFamily="body"
              fontSize="14px"
              fontWeight={700}
              zIndex={100}
              whiteSpace="nowrap"
              px="6px"
              color={'gray.700'}
            >
              Documentation
            </Text>
          </Box>
        </HStack>

        <Icon as={ChevronRightIcon} color={'gray.700'} />
      </Center>
    </Link>
  );
}

export function DevelopersMobile({
  setSelected,
  onClose,
  title
}: Props): JSX.Element {
  const [selectedSection, setSelectedSection] = useState<string | null>(
    DevelopersSections[0].name
  );

  const handleSectionHover = (sectionName: string) => {
    setSelectedSection(sectionName);
  };

  return (
    <>
      <Flex
        flexDir="column"
        h="full"
        w="full"
        justifyContent={'space-between'}
        alignItems={'center'}
        pb="40px"
      >
        <Header onClose={onClose} setSelected={setSelected} heading={title} />

        <Stack w="100vw" pos="relative" gap={6} px={6} pt="14px">
          <>
            <Flex
              flexDir="column"
              pos="relative"
              alignSelf={'center'}
              gap="10px"
              py="24px"
              px="30px"
            >
              {DevelopersSections.map((section, index) => {
                const isHovered = selectedSection === section.name;

                const handleMouseEnter = () => {
                  handleSectionHover(section.name);
                };

                const handleMouseLeave = () => {
                  handleSectionHover(DevelopersSections[0].name);
                };

                const handleClick = () => {
                  if (setSelected) {
                    setSelected(section.name as string);
                  }
                };

                return (
                  <Flex
                    key={index} // Adding 'key' prop here
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onTouchStart={handleMouseEnter}
                    onTouchEnd={handleMouseLeave}
                    onClick={handleClick}
                    cursor="pointer"
                    bg={isHovered ? '#F0F4F9' : 'transparent'}
                    rounded="12px"
                    shadow={isHovered ? '2px 2px 4px rgba(0, 0, 0, 0.15)' : ''}
                    w="full"
                    minW="80vw"
                    h="75px"
                    flexDir="row"
                    py="10px"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Flex flexDir="column" pl="14px">
                      <Text fontSize={14} fontWeight="700">
                        {section.name}
                      </Text>
                      <Text fontSize={12} fontWeight="400">
                        {section.description}
                      </Text>
                    </Flex>
                    <Box pos={'relative'} pr="6px">
                      <Icon
                        as={() => (
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 12L10 8L6 4"
                              stroke="#6C47FF"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      />
                    </Box>
                  </Flex>
                );
              })}
            </Flex>
          </>
          <DocsButton />
        </Stack>
      </Flex>
    </>
  );
}
