export function NodeIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.65005 0.101514L1.3788 3.7207C1.14423 3.856 1 4.10592 1 4.37667V11.6204C1 11.891 1.14423 12.1409 1.37865 12.2765L7.64975 15.8985C7.88433 16.0338 8.17308 16.0338 8.4075 15.8985L14.6773 12.2765C14.9115 12.1409 15.0558 11.891 15.0558 11.6204V4.37637C15.0558 4.10591 14.9115 3.856 14.677 3.7207L8.40735 0.101514C8.29022 0.0339378 8.15939 -1.03949e-07 8.0287 -1.03949e-07C7.89787 -1.03949e-07 7.76719 0.0339378 7.65005 0.101514"
        fill="url(#paint0_linear_20783_112425)"
      />
      <path
        d="M1.15625 12.0809C1.21594 12.159 1.2911 12.226 1.37907 12.2769L6.75861 15.3839L7.65419 15.8989C7.78815 15.9762 7.941 16.0089 8.09118 15.9979C8.14134 15.9938 8.19135 15.9845 8.24017 15.9704L14.8532 3.86073C14.8026 3.8064 14.7436 3.75922 14.6774 3.72111L10.5712 1.35075L8.40122 0.101927C8.33915 0.0662041 8.27262 0.0404537 8.2043 0.0236341L1.15625 12.0809Z"
        fill="url(#paint1_linear_20783_112425)"
      />
      <path
        d="M7.95247 0.0042089C7.84754 0.0146281 7.74439 0.0472252 7.64987 0.101703L1.39648 3.71047L8.13792 15.9922C8.2314 15.9786 8.32293 15.9474 8.40733 15.8987L14.6771 12.2767C14.8707 12.1646 15.0029 11.9744 15.0429 11.7592L8.16977 0.0167142C8.11902 0.00659265 8.06737 0.00152887 8.01573 0.00152887C7.99459 0.00152887 7.97345 0.00242275 7.95247 0.0042089"
        fill="url(#paint2_linear_20783_112425)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20783_112425"
          x1="9.38065"
          y1="11.0707"
          x2="5.81434"
          y2="4.58043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#41873F" />
          <stop offset="0.3288" stopColor="#418B3D" />
          <stop offset="0.6352" stopColor="#419637" />
          <stop offset="0.9319" stopColor="#3FA92D" />
          <stop offset="1" stopColor="#3FAE2A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20783_112425"
          x1="7.53662"
          y1="7.60269"
          x2="15.8062"
          y2="12.8592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#41873F" />
          <stop offset="0.1376" stopColor="#41873F" />
          <stop offset="0.4032" stopColor="#54A044" />
          <stop offset="0.7136" stopColor="#66B848" />
          <stop offset="0.9081" stopColor="#6CC04A" />
          <stop offset="1" stopColor="#6CC04A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20783_112425"
          x1="0.779293"
          y1="8.01783"
          x2="15.2789"
          y2="8.01783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6CC04A" />
          <stop offset="0.0919" stopColor="#6CC04A" />
          <stop offset="0.2864" stopColor="#66B848" />
          <stop offset="0.5968" stopColor="#54A044" />
          <stop offset="0.8624" stopColor="#41873F" />
          <stop offset="1" stopColor="#41873F" />
        </linearGradient>
      </defs>
    </svg>
  );
}
