import * as React from 'react';

export function DevelopersGradient(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 12.75L16.5 9L12.75 5.25M5.25 5.25L1.5 9L5.25 12.75M10.5 2.25L7.5 15.75"
        stroke="url(#paint0_linear_780_26823)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_780_26823"
          x1="2"
          y1="0.5"
          x2="17"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
