import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleObject,
  SystemStyleObject
} from '@chakra-ui/theme-tools';

const baseStyleOverlay: SystemStyleObject = {
  bg: 'blackAlpha.600'
};

const baseStyleDialog: SystemStyleObject = {
  py: '8',
  rounded: '2xl',
  maxW: 'unset'
};

const baseStyleHeader: SystemStyleObject = {
  mb: '8',
  py: '0',
  px: '6',
  fontWeight: 'medium'
};

const baseStyleBody: SystemStyleObject = {
  py: '0',
  px: '6'
};

const baseStyleFooter: SystemStyleObject = {
  mt: '8',
  py: '0',
  px: '6',
  justifyContent: 'space-between'
};

const baseStyle: PartsStyleObject<typeof parts> = {
  overlay: baseStyleOverlay,
  dialog: baseStyleDialog,
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter
};

const defaultProps = {
  size: 'xl'
};

export const Modal = {
  parts: parts.keys,
  baseStyle,
  defaultProps,
  sizes: {
    '2.5xl': { dialog: { maxW: '712px' } },
    '4xl': { dialog: { maxW: '956px' } }
  }
};
