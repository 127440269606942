export function SecurityGradient(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_749_26911)">
        <path
          d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
          stroke="url(#paint0_linear_749_26911)"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2987 9.89602C10.2458 9.73729 10.2193 9.65792 10.2207 9.593C10.2221 9.52472 10.2313 9.48893 10.2627 9.4283C10.2926 9.37065 10.3725 9.29628 10.5324 9.14752C10.9739 8.73671 11.25 8.1506 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.1506 7.02614 8.73671 7.46761 9.14752C7.62747 9.29627 7.7074 9.37065 7.7373 9.4283C7.76874 9.48893 7.77787 9.52472 7.77932 9.593C7.78069 9.65792 7.75424 9.73729 7.70133 9.89602L7.01325 11.9603C6.92437 12.2269 6.87993 12.3602 6.90658 12.4663C6.9299 12.5592 6.98788 12.6397 7.06863 12.6912C7.16087 12.75 7.3014 12.75 7.58246 12.75H10.4175C10.6986 12.75 10.8391 12.75 10.9314 12.6912C11.0121 12.6397 11.0701 12.5592 11.0934 12.4663C11.1201 12.3602 11.0756 12.2269 10.9868 11.9603L10.2987 9.89602Z"
          stroke="url(#paint1_linear_749_26911)"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_749_26911"
          x1="4"
          y1="2"
          x2="17"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_749_26911"
          x1="4"
          y1="2"
          x2="17"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
        <clipPath id="clip0_749_26911">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
