import { useEffect } from 'react';
import { useUser } from '@clerk/nextjs';
import { AnalyticsBrowser } from '@segment/analytics-next';

const analytics = new AnalyticsBrowser();

// @ts-expect-error
globalThis.__analyticsBrowser = analytics;

export function useSegment(): AnalyticsBrowser {
  return analytics;
}

export function useSegmentIdentify(): void {
  const { user } = useUser();
  const analytics = useSegment();

  useEffect(() => {
    const isIdentified = window.localStorage.getItem('segment-identified');

    if (user && !isIdentified) {
      analytics.identify(user.id, {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.primaryEmailAddress?.emailAddress,
        createdAt: user.createdAt?.toISOString()
      });

      localStorage.setItem('segment-identified', 'true');
    }
  }, [analytics, user]);

  return;
}
