import * as React from 'react';

export function OrgSwitcherGradient(props) {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 14.5703H3M3 14.5703L6 11.1417M3 14.5703L6 17.9989M3 5.99888H15M15 5.99888L12 2.57031M15 5.99888L12 9.42746"
        stroke="url(#paint0_linear_749_27161)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_27161"
          x1="2"
          y1="2.4989"
          x2="17"
          y2="17.9989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
