import { colors } from './colors';

// chakra-bug: need to explicitly reference colors here
export const shadows = {
  dropShadow1: '0px 12px 24px rgba(0, 0, 0, 0.08)',
  dropShadow2: '0px 24px 48px rgba(0, 0, 0, 0.16)',
  dropShadow3: '0px 12px 24px rgba(0, 0, 0, 0.32)',

  containerShadow:
    '0px 42px 56px rgba(5, 1, 54, 0.42), 0px 17.5466px 23.3955px rgba(5, 1, 54, 0.301919), 0px 9.38125px 12.5083px rgba(5, 1, 54, 0.250365), 0px 5.25905px 7.01207px rgba(5, 1, 54, 0.21), 0px 2.79304px 3.72406px rgba(5, 1, 54, 0.169635), 0px 1.16225px 1.54966px rgba(5, 1, 54, 0.118081)',

  focusRing: {
    primary: `inset 0px 0px 0px 2px ${colors.primary['500']} !important`,
    orange: `inset 0px 0px 0px 2px ${colors.orange['500']} !important`
  },

  secondaryButtonNormal: '0px 1px 2px rgba(57, 67, 86, 0.08)',
  secondaryButtonHover: '0px 4px 8px -2px rgba(57, 67, 86, 0.12)',

  cookiesModal:
    '0px 5px 15px 0px rgba(0, 0, 0, 0.08), 0px 15px 35px -5px rgba(25, 28, 33, 0.20), 0px 0px 0px 1px rgba(25, 28, 33, 0.06)',
  cookiesModalContent:
    '0px 0px 2px 0px rgba(0, 0, 0, 0.08), 0px 1px 2px 0px rgba(25, 28, 33, 0.06), 0px 0px 0px 1px rgba(25, 28, 33, 0.04)',
  cookiesBanner:
    '0px 6px 24px 0px rgba(34, 42, 53, 0.12), 0px 0px 0px 1px rgba(34, 42, 53, 0.05), 0px 4px 8px 0px rgba(34, 42, 53, 0.08), 0px 1px 1px 0px rgba(34, 42, 53, 0.10)',
  cookiesBannerAcceptButton:
    '0px 0px 0px 1px #222A35, 0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset, 0px 2px 3px 0px rgba(34, 42, 53, 0.20)'
};
