import * as React from 'react';

export function SecurityClerkGradient(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.5V5.1C15 3.83988 15 3.20982 14.7548 2.72852C14.539 2.30516 14.1948 1.96095 13.7715 1.74524C13.2902 1.5 12.6601 1.5 11.4 1.5H6.6C5.33988 1.5 4.70982 1.5 4.22852 1.74524C3.80516 1.96095 3.46095 2.30516 3.24524 2.72852C3 3.20982 3 3.83988 3 5.1V12.9C3 14.1601 3 14.7902 3.24524 15.2715C3.46095 15.6948 3.80516 16.039 4.22852 16.2548C4.70982 16.5 5.33988 16.5 6.6 16.5H7.875M9.75 8.25H6M8.25 11.25H6M12 5.25H6M14.4375 12.75V11.4375C14.4375 10.7126 13.8499 10.125 13.125 10.125C12.4001 10.125 11.8125 10.7126 11.8125 11.4375V12.75M11.7 15.75H14.55C14.97 15.75 15.1801 15.75 15.3405 15.6683C15.4816 15.5963 15.5963 15.4816 15.6683 15.3405C15.75 15.1801 15.75 14.97 15.75 14.55V13.95C15.75 13.53 15.75 13.3199 15.6683 13.1595C15.5963 13.0184 15.4816 12.9037 15.3405 12.8317C15.1801 12.75 14.97 12.75 14.55 12.75H11.7C11.28 12.75 11.0699 12.75 10.9095 12.8317C10.7684 12.9037 10.6537 13.0184 10.5817 13.1595C10.5 13.3199 10.5 13.53 10.5 13.95V14.55C10.5 14.97 10.5 15.1801 10.5817 15.3405C10.6537 15.4816 10.7684 15.5963 10.9095 15.6683C11.0699 15.75 11.28 15.75 11.7 15.75Z"
        stroke="url(#paint0_linear_749_26709)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_26709"
          x1="3"
          y1="1.5"
          x2="16"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
