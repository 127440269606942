import { SVGProps } from 'react';

export function ReactLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 12.125C11.4061 12.125 12.1406 11.3905 12.1406 10.4844C12.1406 9.57828 11.4061 8.84375 10.5 8.84375C9.59391 8.84375 8.85938 9.57828 8.85938 10.4844C8.85938 11.3905 9.59391 12.125 10.5 12.125Z"
        fill="#00D8FF"
      />
      <path
        d="M10.5 14.2458C8.52806 14.2935 6.56105 14.0277 4.6725 13.4583C3.8001 13.1818 2.98642 12.7461 2.27259 12.1734C2.0002 11.9777 1.77337 11.7254 1.60764 11.4339C1.44192 11.1423 1.34124 10.8183 1.3125 10.4842C1.3125 9.3994 2.50425 8.33627 4.50056 7.64065C6.43961 7.02427 8.46557 6.72554 10.5 6.75603C12.5073 6.72798 14.5061 7.02131 16.4207 7.6249C17.2609 7.89275 18.047 8.30735 18.7425 8.84946C19.0102 9.036 19.2337 9.27912 19.397 9.56162C19.5603 9.84412 19.6595 10.1591 19.6875 10.4842C19.6875 11.6116 18.3553 12.7541 16.2094 13.4642C14.3574 14.015 12.4319 14.2786 10.5 14.2458ZM10.5 7.5409C8.55364 7.51488 6.61554 7.79887 4.75847 8.38221C2.92097 9.02337 2.09738 9.90012 2.09738 10.4822C2.09738 11.0912 2.98266 12.0487 4.92647 12.7135C6.73317 13.2554 8.61434 13.5077 10.5 13.4609C12.3483 13.494 14.1907 13.2439 15.9633 12.7194C17.9813 12.0506 18.9 11.0919 18.9 10.4842C18.8669 10.2756 18.7909 10.0762 18.6768 9.89856C18.5627 9.72088 18.413 9.56879 18.2372 9.4519C17.6149 8.97096 16.9127 8.60353 16.1628 8.36646C14.3312 7.79143 12.4195 7.51273 10.5 7.5409Z"
        fill="#00D8FF"
      />
      <path
        d="M6.77322 18.6658C6.46708 18.6728 6.16455 18.5986 5.89647 18.4506C4.95672 17.9085 4.63122 16.3453 5.02563 14.2683C5.46049 12.2806 6.21397 10.3763 7.25688 8.62912C8.23517 6.87634 9.48759 5.29138 10.9667 3.93431C11.6184 3.34021 12.3703 2.86629 13.1874 2.53453C13.483 2.39602 13.8054 2.32422 14.1318 2.32422C14.4581 2.32422 14.7805 2.39602 15.0761 2.53453C16.0533 3.09759 16.3768 4.82221 15.9207 7.03575C15.4724 8.91561 14.7385 10.7156 13.7446 12.373C12.8007 14.1051 11.5877 15.6763 10.1509 17.0278C9.47552 17.6452 8.69166 18.1322 7.83897 18.4643C7.4976 18.591 7.13728 18.6592 6.77322 18.6658ZM7.93675 9.01893C6.94194 10.6919 6.21978 12.5126 5.79738 14.4127C5.43447 16.3256 5.78294 17.476 6.28956 17.7687C6.81456 18.0726 8.089 17.7845 9.63644 16.4326C11.008 15.1378 12.1658 13.6338 13.0667 11.9767C14.0188 10.3921 14.7228 8.67121 15.1542 6.87365C15.5834 4.79006 15.2126 3.51431 14.6863 3.21112C14.489 3.13554 14.2782 3.1017 14.0672 3.11176C13.8562 3.12181 13.6496 3.17553 13.4604 3.26953C12.7331 3.56966 12.0644 3.99575 11.4851 4.52821C10.072 5.82811 8.87564 7.34545 7.94135 9.02287L7.93675 9.01893Z"
        fill="#00D8FF"
      />
      <path
        d="M14.2254 18.675C13.3362 18.675 12.2068 18.1369 11.0308 17.1256C9.5258 15.7562 8.25221 14.1524 7.25931 12.3763C6.22948 10.6533 5.48197 8.77651 5.04513 6.81725C4.85567 5.95526 4.82057 5.0665 4.94144 4.19225C4.96938 3.86793 5.06814 3.5537 5.23074 3.2717C5.39334 2.98971 5.61582 2.74681 5.8825 2.56015C6.85835 1.99512 8.51406 2.5759 10.2039 4.07609C11.6087 5.40315 12.8016 6.9378 13.7411 8.62653C14.7703 10.3094 15.5256 12.145 15.9789 14.0649C16.1765 14.9585 16.207 15.881 16.0688 16.7857C16.0359 17.1193 15.9313 17.4419 15.7621 17.7313C15.5928 18.0207 15.363 18.2701 15.0884 18.4624C14.8247 18.6086 14.5268 18.6819 14.2254 18.675ZM7.93853 11.9813C8.89103 13.6786 10.1076 15.2135 11.5427 16.5284C13.0186 17.7983 14.1893 18.0713 14.6927 17.7753C15.2177 17.4702 15.6048 16.2246 15.2058 14.2092C14.7687 12.3763 14.0442 10.6241 13.0593 9.01765C12.1627 7.40139 11.0241 5.93188 9.68285 4.66015C8.0921 3.2479 6.80191 2.93225 6.27625 3.23675C6.11242 3.36989 5.97796 3.53555 5.88139 3.72328C5.78481 3.91101 5.72821 4.11673 5.71516 4.32744C5.61082 5.10712 5.64481 5.89909 5.81556 6.66697C6.23576 8.54065 6.95268 10.3352 7.93919 11.9826L7.93853 11.9813Z"
        fill="#00D8FF"
      />
    </svg>
  );
}
