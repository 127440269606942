import * as React from 'react';

export function StatusIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.182 5.81663C13.9393 7.57399 13.9393 10.4232 12.182 12.1806M5.81802 12.1806C4.06066 10.4232 4.06066 7.57397 5.81802 5.81661M3.6967 14.3019C0.767767 11.373 0.767767 6.62424 3.6967 3.69531M14.3033 3.69535C17.2322 6.62428 17.2322 11.373 14.3033 14.3019M10.5 8.99861C10.5 9.82704 9.82843 10.4986 9 10.4986C8.17157 10.4986 7.5 9.82704 7.5 8.99861C7.5 8.17019 8.17157 7.49861 9 7.49861C9.82843 7.49861 10.5 8.17019 10.5 8.99861Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
