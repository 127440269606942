export function UserProfileGradient(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 11.625H5.625C4.57833 11.625 4.05499 11.625 3.62914 11.7542C2.67034 12.045 1.92003 12.7953 1.62918 13.7541C1.5 14.18 1.5 14.7033 1.5 15.75M10.875 5.625C10.875 7.48896 9.36396 9 7.5 9C5.63604 9 4.125 7.48896 4.125 5.625C4.125 3.76104 5.63604 2.25 7.5 2.25C9.36396 2.25 10.875 3.76104 10.875 5.625ZM8.25 15.75L10.576 15.0854C10.6874 15.0536 10.7431 15.0377 10.795 15.0138C10.8412 14.9927 10.885 14.9669 10.9259 14.9368C10.972 14.903 11.013 14.862 11.0949 14.7801L15.9375 9.93753C16.4553 9.41975 16.4553 8.58025 15.9375 8.06248C15.4197 7.54472 14.5803 7.54473 14.0625 8.0625L9.21988 12.9051C9.13796 12.987 9.097 13.028 9.06318 13.0741C9.03314 13.115 9.00734 13.1588 8.98616 13.205C8.96231 13.2569 8.9464 13.3126 8.91457 13.424L8.25 15.75Z"
        stroke="url(#paint0_linear_749_27145)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_27145"
          x1="3.5"
          y1="2"
          x2="17.5"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
