import * as React from 'react';

export function BlogIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 14.25V12H5.25C4.00736 12 3 13.0074 3 14.25M6.6 16.5H12.6C13.4401 16.5 13.8601 16.5 14.181 16.3365C14.4632 16.1927 14.6927 15.9632 14.8365 15.681C15 15.3601 15 14.9401 15 14.1V3.9C15 3.05992 15 2.63988 14.8365 2.31901C14.6927 2.03677 14.4632 1.8073 14.181 1.66349C13.8601 1.5 13.4401 1.5 12.6 1.5H6.6C5.33988 1.5 4.70982 1.5 4.22852 1.74524C3.80516 1.96095 3.46095 2.30516 3.24524 2.72852C3 3.20982 3 3.83988 3 5.1V12.9C3 14.1601 3 14.7902 3.24524 15.2715C3.46095 15.6948 3.80516 16.039 4.22852 16.2548C4.70982 16.5 5.33988 16.5 6.6 16.5Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_26695"
          x1="3"
          y1="0.5"
          x2="16"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
