import clsx from 'clsx';

type Feature = {
  label: string;
  description?: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  href: string;
  gradient?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  external?: boolean;
};

export type Section = {
  name: string;
  description: string;
  features: Feature[];
};

type SubmenuProps = {
  sections: Section[];
  setSelected: (selected: Section) => void;
  selected: Section;
};

export function SliderSelector({
  selected,
  sections,
  setSelected
}: SubmenuProps) {
  return (
    <div className="flex h-full w-[260px] cursor-default flex-col p-4">
      {sections.map((section: Section) => {
        return (
          <div
            className={clsx(
              'flex flex-1 items-center rounded-lg p-4 text-left transition-colors hover:bg-gray-50/50',
              {
                'bg-gray-50/50': section === selected
              }
            )}
            onMouseEnter={() => {
              setSelected(section);
            }}
            key={section.name}
          >
            <div className="space-y-1">
              <div className="text-sm font-semibold tracking-tight text-gray-900">
                {section.name}
              </div>
              <div className="text-xs tracking-tight text-gray-600/75">
                {section.description}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
