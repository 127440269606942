import * as React from 'react';

export function MultifactorGradient(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 10.8748V6.37478M6.75 8.62478H11.25M15 8.99978C15 12.6811 10.9845 15.3586 9.52349 16.2109C9.35745 16.3078 9.27443 16.3562 9.15726 16.3814C9.06633 16.4009 8.93367 16.4009 8.84274 16.3814C8.72557 16.3562 8.64255 16.3078 8.47651 16.2109C7.01547 15.3586 3 12.6811 3 8.99978V5.41298C3 4.81335 3 4.51353 3.09807 4.25581C3.18471 4.02814 3.32549 3.82499 3.50824 3.66393C3.71512 3.48161 3.99585 3.37634 4.5573 3.16579L8.57865 1.65779C8.73457 1.59932 8.81253 1.57008 8.89274 1.55849C8.96388 1.54821 9.03612 1.54821 9.10726 1.55849C9.18747 1.57008 9.26543 1.59932 9.42135 1.65779L13.4427 3.16579C14.0042 3.37634 14.2849 3.48161 14.4918 3.66393C14.6745 3.82499 14.8153 4.02814 14.9019 4.25581C15 4.51353 15 4.81335 15 5.41298V8.99978Z"
        stroke="url(#paint0_linear_749_26871)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_26871"
          x1="3"
          y1="1.99976"
          x2="15"
          y2="15.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
