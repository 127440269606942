import { Flex, Heading, Button, Box, Text } from '@chakra-ui/react';
import { URLS } from '@lib';
import Link from 'next/link';
import { useSegment } from '@hooks/use-segment';
import { openPopupWidget } from 'react-calendly';
import Image from 'next/image';

export const StartNow = (): JSX.Element => {
  const analytics = useSegment();
  return (
    <Flex
      flexDir="column"
      alignItems={{ base: 'center', lg: 'flex-start' }}
      justifyContent={{ base: 'center', lg: 'space-evenly' }}
      mb={12}
      mr={{ lg: 12 }}
    >
      <Box
        boxSize={{
          base: 16,
          md: 20
        }}
        mb={10}
        pos="relative"
        h="36px"
      >
        <Image
          src="/images/clerk-logomark-square.svg"
          alt="Clerk's logo"
          fill
        />
      </Box>

      <Heading mb={4} zIndex={2} textAlign={{ base: 'center', lg: 'left' }}>
        Start now,
        <br />
        no strings attached
      </Heading>

      <Text
        color="black"
        paddingTop={2}
        mb={8}
        flexDir={{ base: 'column', lg: 'row' }}
        textAlign={{ base: 'center', lg: 'left' }}
        w="80%"
      >
        Start completely free for up to 10,000 monthly active users and up to
        100 monthly active orgs. No credit card required.
      </Text>

      <Box
        display="flex"
        w={'full'}
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'center', lg: 'flex-start' }}
        alignItems={{ base: 'center' }}
        gap={4}
      >
        <Link
          legacyBehavior
          href={process.env.NEXT_PUBLIC_DASHBOARD_URL as string}
          passHref
        >
          <Button
            as="a"
            w={{ base: '84vw', md: 225 }}
            variant="base"
            h={12}
            fontSize="md"
            zIndex={100}
            onClick={() => {
              analytics.track(
                'Marketing_Pricing_Start Building Button Clicked',
                { surface: 'Marketing', location: 'Pricing' }
              );
            }}
          >
            Start Building
          </Button>
        </Link>

        <Button
          w={{ base: '84vw', md: 225 }}
          variant="white"
          h={12}
          fontSize="md"
          onClick={() => {
            openPopupWidget({
              url: URLS.calendlyUrl
            });
            analytics.track(
              'Marketing_Pricing_Schedule a Demo Button Clicked',
              { surface: 'Marketing', location: 'Pricing' }
            );
          }}
        >
          Schedule a Demo
        </Button>
      </Box>
    </Flex>
  );
};
