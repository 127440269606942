import * as React from 'react';

export function DevelopersIcon(props) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 11.75L16.5 8L12.75 4.25M5.25 4.25L1.5 8L5.25 11.75M10.5 1.25L7.5 14.75"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
