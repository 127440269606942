import * as React from 'react';

export function StartupsIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 11.25L6.75 9M9 11.25C10.0476 10.8516 11.0527 10.349 12 9.75M9 11.25V15C9 15 11.2725 14.5875 12 13.5C12.81 12.285 12 9.75 12 9.75M6.75 9C7.14911 7.96458 7.65165 6.97205 8.25 6.0375C9.12389 4.64024 10.3407 3.48979 11.7848 2.69557C13.2288 1.90135 14.852 1.48978 16.5 1.5C16.5 3.54 15.915 7.125 12 9.75M6.75 9H3C3 9 3.4125 6.7275 4.5 6C5.715 5.19 8.25 6 8.25 6M3.375 12.375C2.25 13.32 1.875 16.125 1.875 16.125C1.875 16.125 4.68 15.75 5.625 14.625C6.1575 13.995 6.15 13.0275 5.5575 12.4425C5.26598 12.1643 4.88197 12.0035 4.47917 11.991C4.07637 11.9786 3.68316 12.1153 3.375 12.375Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_609_24979"
          x1="2"
          y1="3.50006"
          x2="14"
          y2="16.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset="1" stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
