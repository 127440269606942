import { Flex, Icon, Box, Text, HStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import Link from 'next/link';
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon';

interface BlogCardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blog: any;
}

const BlogCard = ({ blog }: BlogCardProps): JSX.Element => {
  function formatTitle(title: string) {
    if (title?.length > 140) {
      return title?.substring(0, 140) + '...';
    }
    return title;
  }

  const formattedDate =
    blog.date && format(new Date(blog.date), 'MMM dd, yyyy');

  return (
    <>
      <Link href={blog.slug.current}>
        <Box
          rounded="xl"
          height={71}
          width={524}
          bg="gray.25"
          border=" 0.5px solid #E4E9F2"
          borderRadius=" 8px"
          role="group"
          _hover={{ filter: 'drop-shadow(2px 1px 4px rgba(0, 0, 0, 0.15))' }}
        >
          <Flex w="full" justifyContent={'flex-end'} pt={2} pr={2}>
            <Icon
              as={ArrowRightUpLineIcon}
              color="gray.500"
              _groupHover={{
                color: 'primary.500'
              }}
            />
          </Flex>

          <Flex
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mx="24px"
            fontFamily="body"
            fontStyle="normal"
            fontSize="14px"
            lineHeight="20px"
            letterSpacing="-0.02em"
            color="gray.500"
            pos="relative"
            top={-2}
          >
            <Text
              textAlign="left"
              fontWeight={700}
              fontFamily="body"
              _groupHover={{
                color: 'primary.500'
              }}
            >
              {formatTitle(blog.title)}
            </Text>
            <HStack>
              <Text fontWeight={400} textAlign="left">
                {blog.author.name}
              </Text>
              <Text textAlign="left" fontWeight={400}>
                {formattedDate}
              </Text>
            </HStack>
          </Flex>
        </Box>
      </Link>
    </>
  );
};

export default BlogCard;
