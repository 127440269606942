import {
  Box,
  CloseButton,
  Heading,
  IconButton,
  Icon,
  Text,
  Grid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow
} from '@chakra-ui/react';
import { useState } from 'react';
import { DiscordIcon } from './icons/discordIcon';
import { BookIcon } from './icons/bookIcon';
import { ChatAltIcon } from './icons/chatAltIcon';
import { ContactForm } from './contactForm';
import { PhoneIcon } from '@heroicons/react/outline';
import { ChatIcon } from './icons/chatIcon';
// Used to link to docs, discord etc. before revealing chat
function LinkRow(props: {
  label: string;
  icon: React.ReactNode;
  href: string;
  onClick?: (e: React.MouseEvent) => void;
}) {
  return (
    <Grid
      borderRadius="md"
      alignItems="center"
      _hover={{ background: '#f3e8ff', color: '#6d28d9' }}
      textDecoration="none"
      fontWeight="500"
      color="inherit"
      templateColumns={'16px 1fr'}
      gap="8px"
      padding="8px 8px"
      margin="0 -8px 1px"
      as="a"
      href={props.href}
      onClick={props.onClick}
    >
      <Icon
        fontSize="18px"
        display="flex"
        justifyContent="center"
        alignContent="center"
      >
        {props.icon}
      </Icon>
      {props.label}
    </Grid>
  );
}

export function HelpButtonWrapper({ children }: { children: React.ReactNode }) {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  return (
    <Box>
      <span style={{ position: 'relative', zIndex: 1500 }}>
        <Popover
        closeOnBlur={false}
        placement="top"
        closeDelay={0}
        onClose={async () => {
          await new Promise(r => setTimeout(r, 200));
          setIsContactFormOpen(false);
        }}
      >
        {({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
          <>
            <PopoverTrigger>
              <IconButton
                zIndex={99}
                bottom="1.5rem"
                right="1.5rem"
                position="fixed"
                size="lg"
                height="48px"
                width="48px"
                isRound={true}
                aria-label={isOpen ? 'Close Button' : 'Open Chat'}
                icon={
                  isOpen ? (
                    <CloseButton
                      onClick={e => {
                        e.preventDefault();
                        onClose();
                      }}
                      color="white"
                    />
                  ) : (
                    <ChatIcon color="white" height="24" />
                  )
                }
              />
            </PopoverTrigger>
            <PopoverContent
              borderRadius="0px"
              padding="24px"
              minH={isContactFormOpen ? '430px' : '275px'}
              width={isContactFormOpen ? '330px' : '260px'}
              bg="white"
              mr="2"
            >
              <PopoverArrow bg="white" />

              {isContactFormOpen ? (
                <ContactForm
                  onSubmit={() => {
                    onClose();
                  }}
                />
              ) : (
                <>
                  <Heading my="2" fontSize="1.05rem" as="h4" color="black">
                    Need help?
                  </Heading>
                  <Text fontSize="0.8rem" mb="1">
                    Get help with setting up and using Clerk for your
                    application
                  </Text>
                  <LinkRow
                    label="Join Discord"
                    href="https://discord.gg/vVQHBXkSEY"
                    icon={<DiscordIcon />}
                  />
                  <LinkRow
                    label="Read Documentation"
                    href="https://clerk.com/docs"
                    icon={<BookIcon />}
                  />
                  <LinkRow
                    label="Book a Sales Call"
                    href="https://calendly.com/clerk-auth/intro-call"
                    icon={<PhoneIcon />}
                  />
                  <LinkRow
                    label="Contact Support"
                    href="#"
                    icon={<ChatAltIcon />}
                    onClick={e => {
                      e.preventDefault();
                      setIsContactFormOpen(true);
                    }}
                  />
                </>
              )}
            </PopoverContent>
          </>
        )}
        </Popover>
      </span>
      {children}
    </Box>
  );
}
