import { SVGProps } from 'react';

export function SupabaseLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5179 17.6964C10.0456 18.2753 9.08795 17.9581 9.07657 17.2189L8.91016 6.40625H16.3793C17.7322 6.40625 18.4867 7.92724 17.6455 8.95857L10.5179 17.6964Z"
        fill="url(#paint0_linear_749_27602)"
      />
      <path
        d="M10.5179 17.6964C10.0456 18.2753 9.08795 17.9581 9.07657 17.2189L8.91016 6.40625H16.3793C17.7322 6.40625 18.4867 7.92724 17.6455 8.95857L10.5179 17.6964Z"
        fill="url(#paint1_linear_749_27602)"
        fill-opacity="0.2"
      />
      <path
        d="M7.48118 0.299025C7.9535 -0.280005 8.91119 0.0372615 8.92257 0.776534L8.99549 11.5891H1.61979C0.266877 11.5891 -0.487665 10.0681 0.353614 9.0368L7.48118 0.299025Z"
        fill="#3ECF8E"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_27602"
          x1="198.521"
          y1="246.149"
          x2="623.29"
          y2="429.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#249361" />
          <stop offset="1" stop-color="#3ECF8E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_749_27602"
          x1="27.0077"
          y1="-146.088"
          x2="290.556"
          y2="363.593"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
