import React from 'react';
import {
  DocsCurrentView,
  DocsCurrentViewOptions,
  ContextDocsNavigation
} from '@types';

export const DocsNavigationContext = React.createContext<ContextDocsNavigation>(
  {} as any
);

type Props = {
  children: React.ReactNode;
};

const defaultCurrentView = {
  view: 'parents' as DocsCurrentViewOptions,
  icon: null,
  name: '',
  slug: '/docs',
  categories: []
};

export function DocsNavigationProvider({ children }: Props) {
  const [currentView, setCurrentView] =
    React.useState<DocsCurrentView>(defaultCurrentView);

  return (
    <DocsNavigationContext.Provider
      value={{
        currentView,
        setCurrentView,
        resetCurrentView: () => {
          // Keep current data, so content doesn't disappear during the transition
          setCurrentView({
            ...currentView,
            view: 'parents'
          });

          // Wait for the transition to finish, then reset to default
          setTimeout(() => {
            setCurrentView(defaultCurrentView);
          }, 500);
        }
      }}
    >
      {children}
    </DocsNavigationContext.Provider>
  );
}
