export const SentryLoader = () => {
  if (!process.env.NEXT_PUBLIC_SENTRY_DSN) {
    return null;
  }

  const scriptSrc = `https://js.sentry-cdn.com/${process.env.NEXT_PUBLIC_SENTRY_DSN}.min.js`;

  return (
    <script
      src={scriptSrc}
      crossOrigin="anonymous"
      async // Add the 'async' attribute to load the script asynchronously
    />
  );
};
