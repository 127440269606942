import { Box, Button, Image, Link, VStack, Flex } from '@chakra-ui/react';

export type Banner = {
  onAcceptAll: () => void;
  onDenyAll: () => void;
  onOpenDialogClick: () => void;
};

export const Banner = (props: Banner) => {
  return (
    <Flex
      bottom="7"
      justify="center"
      position="fixed"
      width="full"
      fontSize="cookieConsent"
      px="3"
      pointerEvents="none"
      zIndex={1000} // Show cookie banner but not above Cookie manager modal
    >
      <Flex
        maxW="xl"
        bg="white"
        borderRadius="2xl"
        boxShadow="cookiesBanner"
        gap="4"
        direction={{ base: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        p="4"
        pointerEvents="all"
      >
        <Flex
          bg="gray.50"
          py={'3'}
          px={'2.5'}
          height="100%"
          minW={'16'}
          filter="grayscale(1)"
          borderRadius="xl"
          display={{ base: 'none', md: 'flex' }}
          justifyContent={'center'}
        >
          <Image src="/images/clerk-logomark-light.svg" alt="Clerk logo" />
        </Flex>

        <VStack
          spacing={0}
          textAlign="left"
          align="start"
          color="gray.700"
          fontWeight="450"
          lineHeight="5"
        >
          <Box>
            We use cookies to collect data to improve your experience on our
            site. Read our{' '}
            <Link
              colorScheme="primary.500"
              textDecoration="underline"
              href="/privacy"
            >
              cookie policy
            </Link>
            .
          </Box>
          <Box>
            You can{' '}
            <Link
              colorScheme="primary.500"
              textDecoration="underline"
              onClick={props.onOpenDialogClick}
            >
              change your preferences
            </Link>{' '}
            at any time.
          </Box>
        </VStack>
        <Flex gap={1}>
          <Button
            variant="ghost"
            minHeight="4"
            pl={{ base: '0', md: '4' }}
            onClick={props.onDenyAll}
          >
            Decline
          </Button>
          <Button
            variant="gray-gradient"
            minHeight="4"
            onClick={props.onAcceptAll}
            boxShadow="cookiesBannerAcceptButton"
          >
            Accept
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
