import { SVGProps } from 'react';

export function GatsbyLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_749_27848)">
        <path
          d="M9 0.75C13.5375 0.75 17.25 4.4625 17.25 9C17.25 13.5375 13.5375 17.25 9 17.25C4.4625 17.25 0.75 13.5375 0.75 9C0.75 4.4625 4.4625 0.75 9 0.75ZM15.375 8.8125H11.1989V10.0057H14.0625C13.6449 11.7955 12.3324 13.2869 10.6023 13.8835L3.74148 7.02273C4.45739 4.93466 6.4858 3.44318 8.8125 3.44318C10.6023 3.44318 12.2131 4.33807 13.2273 5.71023L14.1222 4.93466C12.929 3.32386 11.0199 2.25 8.8125 2.25C5.71023 2.25 3.08523 4.45739 2.42898 7.38068L10.304 15.2557C13.1676 14.5398 15.375 11.9148 15.375 8.8125ZM2.25 8.87216C2.25 10.5426 2.90625 12.1534 4.15909 13.4062C5.41193 14.6591 7.08239 15.3153 8.69318 15.3153L2.25 8.87216Z"
          fill="#5E3994"
        />
      </g>
      <defs>
        <clipPath id="clip0_749_27848">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
