export const Checkbox = {
  parts: ['label'],

  baseStyle: {
    control: {
      _checked: {
        bg: 'primary.500',
        borderColor: 'primary.500'
      },

      _focus: {
        boxShadow: 'dropShadow1'
      }
    }
  },

  sizes: {
    md: {
      control: {
        boxSize: '5',
        rounded: 'lg',
        borderColor: 'gray.100'
      },

      label: {
        fontSize: 'xs',
        lineHeight: '1rem'
      }
    }
  }
};
