import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  SystemStyleFunction
} from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import { textStyles } from '@theme/foundations/textStyles';

const baseStyleText: SystemStyleFunction = props => {
  return {
    color: mode('danger.500', 'danger.300')(props),
    mt: 2,
    ...textStyles['sm-normal']
  };
};

const baseStyleIcon: SystemStyleFunction = props => {
  return {
    color: mode('danger.500', 'danger.300')(props)
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = props => ({
  text: baseStyleText(props),
  icon: baseStyleIcon(props)
});

export const FormError = {
  parts: parts.keys,
  baseStyle
};
