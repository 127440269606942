import { Flex, Text, Box, VStack } from '@chakra-ui/react';
import { CompanySections } from '../content';
import { Header } from './header';
import { useState, useEffect } from 'react';
import BlogCardMobile from './blog-card-mobile';
import { NavItemWithIconMobile } from './nav-item-mobile';
import { Post } from '@components/blog';

interface Props {
  setSelected?: (selected: string | null) => void;
  onClose?: () => void;
  title: string;
}

const sanityClient = require('@sanity/client');
const client = sanityClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  useCdn: true // Enable this for faster response times in production
});

export function CompanyMobile({
  setSelected,
  onClose,
  title
}: Props): JSX.Element {
  const [blogs, setBlogs] = useState<Post[]>([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const query = `*[_type == "blog" && live == true && !(_id in path('drafts.**'))] | order(date desc, _createdAt desc) [0...3] {
          title,
          slug,
          "author": author->{name, picture},
          date,
          "coverImage": mainImage.asset->url
        }`;
        const result = await client.fetch(query);
        setBlogs(result);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <Flex
        flexDir="column"
        h="full"
        w="full"
        justifyContent={'space-between'}
        alignItems={'center'}
        pb="40px"
      >
        <Header onClose={onClose} setSelected={setSelected} heading={title} />

        <Flex
          pos="relative"
          flexDir={'column'}
          justifyContent={'flex-start'}
          pl="26px"
          pt="10px"
          w="full"
        >
          {CompanySections[0].features.map((feature, index) => (
            <Box key={index}>
              <NavItemWithIconMobile
                label={feature.label}
                href={feature.href}
                icon={feature.icon}
                gradient={feature.gradient}
                iconSize="18px"
              />
            </Box>
          ))}
        </Flex>

        <Text
          fontWeight={700}
          fontSize={'14px'}
          fontFamily={'heading'}
          pt="10px"
          pb={'20px'}
          pl="29px"
          pr="35px"
          textAlign={'left'}
          w="full"
        >
          From the Blog
        </Text>
        {blogs ? (
          <VStack gap={'15px'} zIndex={100} flex={1} pl="26px" pr="35px">
            {blogs?.map((blog, index) => (
              <Box key={index}>
                <BlogCardMobile blog={blog} />
              </Box>
            ))}
          </VStack>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
}
