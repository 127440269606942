import Link from 'next/link';
import { Box, Flex, Icon, LayoutProps, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useState } from 'react';

type Props = {
  label: string;
  description?: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  gradient?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  iconBoxBg?: string;
  iconColor?: string;
  labelColor?: string;
  iconSize?: LayoutProps['boxSize'];
  href?: string;
  noHover?: boolean;
  withArrow?: boolean;
  maxW?: string;
  external?: boolean;
};

export function NavItemWithIcon({
  label,
  icon,
  gradient,
  iconSize,
  href,
  withArrow,
  maxW,
  external
}: Props) {
  const isNewTab = external;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      href={href ? href : ''}
      target={isNewTab ? '_blank' : undefined}
      rel={isNewTab ? 'noopener noreferrer' : undefined}
    >
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent={'flex-start'}
        padding="8px 24px 8px 12px"
        gap="12px"
        rounded="10px"
        role="group"
        transition="background .2s"
        _hover={{ bg: 'gray.25' }}
        w={{ base: '90vw', xl: 'full' }}
        maxW={maxW ? maxW : '255px'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsHovered(true)}
        onTouchEnd={() => setIsHovered(false)}
      >
        <Flex
          boxSize={'40px'}
          rounded="md"
          transition="background .2s"
          justifyContent={'center'}
          alignItems={'center'}
          minW="40px"
        >
          {isHovered ? (
            <Icon as={gradient} boxSize={iconSize || 6} pos="relative" />
          ) : (
            <Icon
              as={icon}
              boxSize={iconSize || 6}
              color={'gray.700'}
              pos="relative"
            />
          )}
        </Flex>

        <Box fontSize={'14px'} pr="12px" position="relative" zIndex={1}>
          <Flex flexDir="column">
            <Text
              fontFamily="body"
              fontStyle="normal"
              fontWeight={500}
              fontSize={'14px'}
              lineHeight="24px"
              letterSpacing="-0.28px"
              flex="none"
              order={0}
              flexGrow={0}
              whiteSpace="nowrap"
              color={isHovered ? 'primary.500' : 'gray.500'}
            >
              {label}
            </Text>
          </Flex>
        </Box>

        {withArrow && (
          <Icon
            as={ChevronRightIcon}
            justifySelf={'flex-end'}
            pos="relative"
            left={'44vw'}
          />
        )}
      </Flex>
    </Link>
  );
}
