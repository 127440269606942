import * as React from 'react';

export function DocumentationIcon(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 14.5L8.42496 14.3874C7.90398 13.606 7.64349 13.2152 7.29933 12.9324C6.99464 12.6819 6.64357 12.4941 6.2662 12.3795C5.83994 12.25 5.37033 12.25 4.43112 12.25H3.4C2.55992 12.25 2.13988 12.25 1.81901 12.0865C1.53677 11.9427 1.3073 11.7132 1.16349 11.431C1 11.1101 1 10.6901 1 9.85V3.4C1 2.55992 1 2.13988 1.16349 1.81901C1.3073 1.53677 1.53677 1.3073 1.81901 1.16349C2.13988 1 2.55992 1 3.4 1H3.7C5.38016 1 6.22024 1 6.86197 1.32698C7.42646 1.6146 7.8854 2.07354 8.17302 2.63803C8.5 3.27976 8.5 4.11984 8.5 5.8M8.5 14.5V5.8M8.5 14.5L8.57504 14.3874C9.09602 13.606 9.35651 13.2152 9.70067 12.9324C10.0054 12.6819 10.3564 12.4941 10.7338 12.3795C11.1601 12.25 11.6297 12.25 12.5689 12.25H13.6C14.4401 12.25 14.8601 12.25 15.181 12.0865C15.4632 11.9427 15.6927 11.7132 15.8365 11.431C16 11.1101 16 10.6901 16 9.85V3.4C16 2.55992 16 2.13988 15.8365 1.81901C15.6927 1.53677 15.4632 1.3073 15.181 1.16349C14.8601 1 14.4401 1 13.6 1H13.3C11.6198 1 10.7798 1 10.138 1.32698C9.57354 1.6146 9.1146 2.07354 8.82698 2.63803C8.5 3.27976 8.5 4.11984 8.5 5.8"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
