import { SystemStyleObject } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const baseStyle = (props: any): SystemStyleObject => {
  return {
    color: mode('primary.500', 'primary.400')(props),
    _hover: {
      textDecoration: 'none',
      color: mode('primary.700', 'primary.300')(props)
    },
    _focusVisible: {
      boxShadow: 'focusRing.orange'
    }
  };
};

const noHover = {
  _hover: {
    color: 'inherit'
  }
};

const variants = {
  'no-hover': noHover
};

export const Link = {
  baseStyle,
  variants
};
