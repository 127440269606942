import { Flex, Icon, Heading, CloseButton, Box } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@heroicons/react/outline';

interface Props {
  setSelected?: (selected: string | null) => void;
  onClose?: () => void;
  heading: string;
  backSection?: string;
}

export const Header = ({
  setSelected,
  onClose,
  heading,
  backSection
}: Props) => {
  return (
    <>
      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        pb="9px"
        w="full"
        pl="14px"
        pr="18px"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'center'}
          cursor={'pointer'}
          onClick={() => {
            if (setSelected) {
              setSelected(backSection ? backSection : null);
            }
          }}
        >
          <Icon as={ArrowLeftIcon} h="12px" w="12px" />

          <Heading fontSize={'14px'} ml="24px" fontWeight={700}>
            {heading}
          </Heading>
        </Box>
        <Box pos="relative">
          <CloseButton onClick={onClose} size="sm" />
        </Box>
      </Flex>
      <Box
        h="2px"
        w="full"
        bgGradient="linear-gradient(to bottom, #6C47FF, #17CCFC)"
        bgClip="border-box"
      ></Box>
    </>
  );
};
