import { Flex, Icon, Text } from '@chakra-ui/react';
import Link from 'next/link';
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon';

interface BlogCardMobileProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blog: any;
}

const BlogCardMobile = ({ blog }: BlogCardMobileProps): JSX.Element => {
  function formatTitle(title: string) {
    if (title?.length > 140) {
      return title?.substring(0, 140) + '...';
    }
    return title;
  }

  return (
    <>
      <Link href={blog.slug.current}>
        <Flex
          rounded="xl"
          flexDir="row"
          height={71}
          bg="gray.25"
          border="1px solid #E4E9F2"
          borderRadius="8px"
          role="group"
          pos="relative"
          justifyContent={'space-between'}
          flex={1}
          w="86vw"
        >
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="flex-start"
            pl="18px"
            py="10px"
            fontFamily="body"
            fontStyle="normal"
            fontSize="14px"
            letterSpacing="-0.02em"
            color={'gray.500'}
            pos="relative"
            w="80%"
          >
            <Text fontWeight={700} fontFamily="body">
              {formatTitle(blog.title)}
            </Text>
          </Flex>
          <Icon
            pos="relative"
            as={ArrowRightUpLineIcon}
            color={'gray.500'}
            flexShrink={0}
            mt={'5px'}
            h="19px"
            w="19px"
            pr="5px"
          />
        </Flex>
      </Link>
    </>
  );
};

export default BlogCardMobile;
