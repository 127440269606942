import { Flex, Box, Text, Center, HStack, Icon, Link } from '@chakra-ui/react';
import { Header } from './header';
import { NavItemWithIconMobile } from './nav-item-mobile';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { CustomFlowsIcon } from '../icons';
import { useState } from 'react';
import { CustomFlowsGradient } from './gradients';

type Feature = {
  label: string;
  description?: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  href: string;
  gradient?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

interface MobileSubMenuProps {
  setSelected?: (selected: string | null) => void;
  onClose?: () => void;
  features: Feature[];
  title: string;
  customFlows?: boolean;
  docsButton?: boolean;
}

function CustomFlowsButton() {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Link
      href="/docs/custom-flows/overview"
      target={'_blank'}
      rel={'noopener noreferrer'}
    >
      <Center
        role="group"
        display="flex"
        h="57px"
        border="1px solid #E4E9F2"
        cursor="pointer"
        borderRadius="8px"
        bg="gray.50"
        justifyContent="space-between"
        px="14px"
        py="16px"
        mt="28px"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsHovered(true)}
        onTouchCancel={() => setIsHovered(false)}
        onClick={() => setIsHovered(false)}
      >
        <HStack>
          <Box
            display="flex"
            rounded="md"
            transition="background .2s"
            justifyContent="center"
            alignItems="center"
            pl="8px"
          >
            {!isHovered ? (
              <Icon as={CustomFlowsIcon} boxSize="18px" color="gray.700" />
            ) : (
              <Icon as={CustomFlowsGradient} boxSize="18px" />
            )}
          </Box>
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            pos="relative"
            left="8px"
          >
            <Text
              w="100%"
              fontFamily="body"
              fontSize="14px"
              fontWeight={700}
              zIndex={100}
              whiteSpace="nowrap"
              px="6px"
              color={'gray.700'}
            >
              Custom flows
            </Text>
          </Box>
        </HStack>

        <Icon as={ChevronRightIcon} color={'gray.700'} />
      </Center>
    </Link>
  );
}

export function MobileSubMenu({
  setSelected,
  onClose,
  features,
  title,
  customFlows
}: MobileSubMenuProps): JSX.Element {
  return (
    <>
      <Flex
        flexDir="column"
        h="full"
        w="full"
        justifyContent="space-between"
        alignItems="center"
        pb="40px"
      >
        <Header
          onClose={onClose}
          setSelected={setSelected}
          heading={title}
          backSection={
            title == 'Resources' ||
            title == 'SDKs & Framework Integrations' ||
            title == 'Third-Party Integrations'
              ? 'Developers'
              : undefined
          }
        />

        <Flex
          pos="relative"
          flexDir="column"
          justifyContent="flex-start"
          pt="10px"
          w="full"
          px="16px"
          gap="10px"
        >
          {customFlows && <CustomFlowsButton />}
          {features.map((feature, index) => (
            <Box key={index}>
              <NavItemWithIconMobile
                label={feature.label}
                href={feature.href}
                icon={feature.icon}
                gradient={feature.gradient}
                iconSize="18px"
              />
            </Box>
          ))}
        </Flex>
      </Flex>
    </>
  );
}
