import { Box, HStack, VStack, chakra, Grid, GridItem } from '@chakra-ui/react';
import { NavItemWithIcon } from '../nav-item-with-icon';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import React from 'react';
import SelectorBox from '../selector-box';
import { SliderSelector } from '../slider-selector';
import HoverButton from '../hover-button';
import { DocumentationIcon } from '../icons';
import { DocumentationGradient } from '../gradients';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

type Feature = {
  label: string;
  description?: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  href: string;
  gradient?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  external?: boolean;
};

export type Section = {
  name: string;
  description: string;
  features: Feature[];
};

interface SubmenuProps {
  sections: Section[];
}

const allContentHeight = 350;

const leftVStackBoxHeight = 90;
const selecterOffset = 22; // top-padding + a couple extra pixels
const exPaddingOffset = 1; // the last 2 cards look more balanced w/ an extra pixel

export function Developers({ sections }: SubmenuProps): JSX.Element | null {
  const [selected, setSelected] = useState<Section>(sections[0]);

  const SectionControls = useAnimation();
  const ContentControls = useAnimation();

  useEffect(() => {
    if (selected.name == sections[0].name) {
      // Animation for Authentication & Users
      SectionControls.start({
        y: 0,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: 0,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    } else if (selected.name == sections[1].name) {
      // Animation for 'Pre-built Component' section
      SectionControls.start({
        y: `${leftVStackBoxHeight + exPaddingOffset}px`,

        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: -360,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    } else {
      // Animation for last section
      SectionControls.start({
        y: `${2 * leftVStackBoxHeight + exPaddingOffset}px`,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: -770,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    }
  }, [selected, SectionControls, ContentControls, sections]);

  return (
    <Box position={'relative'}>
      <HStack
        w={'830px'}
        h={`${allContentHeight}px`}
        spacing={'0px'}
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
      >
        <SliderSelector
          sections={sections}
          setSelected={setSelected}
          selected={selected}
        />
        <VStack h="full" bg="white" overflow="hidden" flex={1} pr="6px">
          <ChakraBox
            as={motion.div}
            animate={ContentControls}
            key={sections[0].name}
          >
            <Box h={`${allContentHeight}px`} pt="21px">
              <Grid
                templateRows="repeat(6, 1fr)"
                templateColumns="repeat(12, 1fr)"
                w="full"
                gap="6px"
              >
                {sections[0].features.map(feature => (
                  <GridItem key={feature.label} rowSpan={1} colSpan={6}>
                    <NavItemWithIcon
                      label={feature.label}
                      href={feature.href}
                      icon={feature.icon}
                      gradient={feature.gradient}
                      iconSize="18px"
                      external={feature.external}
                    />
                  </GridItem>
                ))}
                <GridItem rowSpan={1} colSpan={12}></GridItem>
                <GridItem rowSpan={1} colSpan={12}>
                  <HoverButton
                    text={'Everything you need to build your app with Clerk.'}
                    header="Documentation"
                    icon={DocumentationIcon}
                    gradient={DocumentationGradient}
                    href="/docs"
                  />
                </GridItem>
              </Grid>
            </Box>

            <Box h={`${allContentHeight}px`} pt="31px">
              <Grid
                templateRows="repeat(6, 1fr)"
                templateColumns="repeat(12, 1fr)"
                w="full"
                gap="6px"
              >
                {sections[1].features.map(feature => (
                  <GridItem
                    key={feature.label}
                    rowSpan={1}
                    colSpan={4}
                    h="min-content"
                  >
                    <NavItemWithIcon
                      label={feature.label}
                      href={feature.href}
                      icon={feature.icon}
                      gradient={feature.gradient}
                      external={feature.external}
                      iconSize="18px"
                      maxW="169.33px"
                    />
                  </GridItem>
                ))}
                <GridItem rowSpan={1} colSpan={12}></GridItem>
                <GridItem rowSpan={1} colSpan={12}>
                  <HoverButton
                    text={'Everything you need to build your app with Clerk.'}
                    header="Documentation"
                    icon={DocumentationIcon}
                    gradient={DocumentationGradient}
                    href="/docs"
                  />
                </GridItem>
              </Grid>
            </Box>

            <Box h={`${allContentHeight}px`} pt="93px">
              <Grid
                templateRows="repeat(6, 1fr)"
                templateColumns="repeat(12, 1fr)"
                w="full"
                gap="6px"
              >
                {sections[2].features.map(feature => (
                  <GridItem key={feature.label} rowSpan={1} colSpan={6}>
                    <NavItemWithIcon
                      label={feature.label}
                      href={feature.href}
                      icon={feature.icon}
                      gradient={feature.gradient}
                      external={feature.external}
                      iconSize="18px"
                    />
                  </GridItem>
                ))}
                <GridItem rowSpan={1} colSpan={6}>
                  <NavItemWithIcon
                    label={'View all'}
                    href={'/docs/integrations/overview'}
                    external={true}
                    icon={() => (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.0002 13.0001V7.00005M13.0002 7.00005H7.00019M13.0002 7.00005L7.00019 12.9999M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
                          stroke="#6C47FF"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                    gradient={() => (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.0002 13.0001V7.00005M13.0002 7.00005H7.00019M13.0002 7.00005L7.00019 12.9999M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
                          stroke="url(#paint0_linear_2235_30331)"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2235_30331"
                            x1="1"
                            y1="1"
                            x2="19"
                            y2="19"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#6C47FF" />
                            <stop offset="1" stop-color="#17CCFC" />
                          </linearGradient>
                        </defs>
                      </svg>
                    )}
                    iconSize="18px"
                  />
                </GridItem>
                <GridItem rowSpan={1} colSpan={12}></GridItem>
                <GridItem rowSpan={1} colSpan={12}>
                  <HoverButton
                    text={'Everything you need to build your app with Clerk.'}
                    header="Documentation"
                    icon={DocumentationIcon}
                    gradient={DocumentationGradient}
                    href="/docs"
                  />
                </GridItem>
              </Grid>
            </Box>
          </ChakraBox>
        </VStack>
      </HStack>
    </Box>
  );
}
