import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = props => {
  return {
    fontFamily: 'body',
    fontWeight: 'bold',
    color: mode('gray.700', 'white')(props),
    fontFeatureSettings:
      "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv06' on, 'cv07' on, 'cv08' on, 'ss01' on, 'ss14' on, 'ss02' on, 'ss04' on"
  };
};

const defaultProps = {
  size: '2xl'
};

const sizes = {
  // New branding
  h1: {
    fontSize: ['2rem', null, '3.5rem'],
    letterSpacing: '-0.02em',
    lineHeight: ['2.5rem', null, '4rem']
  },
  h2: {
    fontSize: ['1.75rem', null, '3rem'],
    letterSpacing: '-0.02em',
    lineHeight: ['2.25rem', null, '3.5rem']
  },
  h3: {
    fontSize: ['1.5rem', null, '2.5rem'],
    letterSpacing: ['-0.02em', null, '-0.01em'],
    lineHeight: ['2rem', null, '3rem']
  },
  h4: {
    fontSize: ['1.25rem', null, '2rem'],
    letterSpacing: '-0.01em',
    lineHeight: ['1.75rem', null, '2.5rem']
  },
  h5: {
    fontSize: ['1rem', null, '1.5rem'],
    letterSpacing: '-0.01em',
    lineHeight: ['1.5rem', null, '2rem']
  },
  h6: {
    fontSize: ['0.9rem', null, '1.1rem'],
    letterSpacing: '-0.01em',
    lineHeight: ['1.4rem', null, '1.6rem']
  },
  // docs headings, we are using separate size definitions here so we can tweak docs typography without impacting the marketing pages
  docsh1: {
    fontSize: ['2.25rem', '2.75rem', '2.25rem'],
    letterSpacing: '-0.02em',
    lineHeight: ['3.5rem', null, '3.5rem']
  },
  docsh2: {
    fontSize: ['1.875rem', null, '1.875rem'],
    letterSpacing: '-0.02em',
    lineHeight: ['2.75rem', null, '2.75rem']
  },
  docsh3: {
    fontSize: ['1.5rem', null, '1.5rem'],
    letterSpacing: ['-0.02em', null, '-0.01em'],
    lineHeight: ['2.25rem', null, '2.25rem']
  },
  docsh4: {
    fontSize: ['1.25rem', null, '1.25rem'],
    letterSpacing: '-0.01em',
    lineHeight: ['1.8', null, '1.8']
  },
  docsh5: {
    fontSize: ['1rem', null, '1rem'],
    letterSpacing: '-0.01em',
    lineHeight: ['1.625', null, '1.625']
  },
  docsh6: {
    fontSize: ['1rem', null, '1rem'],
    letterSpacing: '-0.01em',
    lineHeight: ['1.5', null, '1.5']
  },

  lg: {
    fontSize: '1rem',
    letterSpacing: '0.1em',
    lineHeight: '1.5rem',
    textTransform: 'uppercase'
  },
  md: {
    fontSize: '0.875rem',
    letterSpacing: '0.12em',
    lineHeight: '1.25rem',
    textTransform: 'uppercase'
  },
  sm: {
    fontSize: '0.75rem',
    letterSpacing: '0.12em',
    lineHeight: '1rem',
    textTransform: 'uppercase'
  },

  // Previous sizes
  '3xs': {
    fontSize: ['13px', null, '14px'],
    fontWeight: '500'
  },
  '2xs': {
    fontSize: ['14px', null, '16px'],
    fontWeight: '500'
  },
  xs: {
    fontSize: ['16px', null, '18px'],
    fontWeight: '600'
  },
  // sm: {
  //   fontSize: ['18px', null, '20px'],
  //   fontWeight: '600'
  // },
  // md: {
  //   fontSize: ['20px', null, '24px'],
  //   fontWeight: '600'
  // },
  // lg: {
  //   fontSize: ['20px', null, '24px', '30px']
  // },
  xl: {
    fontSize: ['28px', null, '32px', '38px']
  },
  '2xl': {
    fontSize: ['30px', null, '38px', '46px']
  },
  '3xl': {
    fontSize: ['36px', null, null, '42px', '50px']
  }
};

export const Heading = {
  baseStyle,
  defaultProps,
  sizes
};
