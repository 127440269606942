export function RubyIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3958 10.529L3.26074 15.952L15.0889 15.1496L15.9998 3.22559L12.3958 10.529Z"
        fill="url(#paint0_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1081 15.1411L14.0914 8.12598L11.3223 11.7817L15.1081 15.1411Z"
        fill="url(#paint1_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1224 15.1412L7.67447 14.5566L3.30078 15.9365L15.1224 15.1412Z"
        fill="url(#paint2_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.31083 15.9377L5.17143 9.84375L1.07715 10.719L3.31083 15.9377Z"
        fill="url(#paint3_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3224 11.802L9.61035 5.09766L4.71094 9.68911L11.3224 11.802Z"
        fill="url(#paint4_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6114 5.19183L10.9801 1.41016L9.69043 5.57855L15.6114 5.19183Z"
        fill="url(#paint5_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4462 0.0621762L10.7224 1.56709L9.00391 0.0419922L13.4462 0.0621762Z"
        fill="url(#paint6_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.7633L1.14107 10.6827L0.218039 8.2041L0 12.7633Z"
        fill="url(#paint7_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.15625 8.12607L1.08493 10.7597L5.12026 9.85455L9.72734 5.57393L11.0275 1.44517L8.98027 0L5.49973 1.30227C4.40316 2.32197 2.27526 4.33956 2.19855 4.3775C2.12264 4.41626 0.793407 6.92795 0.15625 8.12607Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41778 3.39449C5.79441 1.03862 8.85825 -0.353273 10.034 0.832738C11.2091 2.01875 9.96298 4.90102 7.58636 7.25609C5.20974 9.61116 2.18385 11.0797 1.00886 9.89374C-0.166933 8.70853 1.04116 5.74956 3.41778 3.39449V3.39449Z"
        fill="url(#paint8_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.31055 15.9356L5.15661 9.82227L11.2875 11.7914C9.07081 13.8696 6.60535 15.6264 3.31055 15.9356Z"
        fill="url(#paint9_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.73633 5.56179L11.3102 11.7946C13.162 9.84806 14.8239 7.75539 15.6378 5.16699L9.73633 5.56179V5.56179Z"
        fill="url(#paint10_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6214 5.19862C16.2513 3.2981 16.3967 0.571644 13.4264 0.0654297L10.9893 1.4113L15.6214 5.19862V5.19862Z"
        fill="url(#paint11_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.7364C0.0872155 15.8731 2.35078 15.9198 3.315 15.9474L1.08777 10.7471L0 12.7364Z"
        fill="#9E1209"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74414 5.5716C11.167 6.44597 14.0347 8.20197 14.0929 8.23427C14.1832 8.28513 15.3292 6.30226 15.5893 5.18164L9.74414 5.5716V5.5716Z"
        fill="url(#paint12_radial_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1543 9.82227L7.62217 14.5825C9.08142 13.7912 10.2241 12.8273 11.2707 11.7946L5.1543 9.82227Z"
        fill="url(#paint13_radial_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.07819 10.753L0.728516 14.9157C1.38828 15.8167 2.29597 15.8951 3.24807 15.8248C2.55923 14.1108 1.18317 10.6836 1.07819 10.753V10.753Z"
        fill="url(#paint14_linear_20783_112437)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9746 1.42209L15.8773 2.10996C15.6156 1.00146 14.8121 0.286139 13.4425 0.0625L10.9746 1.42209Z"
        fill="url(#paint15_linear_20783_112437)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20783_112437"
          x1="14.0572"
          y1="17.4027"
          x2="10.683"
          y2="11.4441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB7655" />
          <stop offset="0.41" stopColor="#E42B1E" />
          <stop offset="0.99" stopColor="#990000" />
          <stop offset="1" stopColor="#990000" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20783_112437"
          x1="15.7384"
          y1="12.3975"
          x2="11.389"
          y2="9.4781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#871101" />
          <stop offset="0.99" stopColor="#911209" />
          <stop offset="1" stopColor="#911209" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20783_112437"
          x1="12.2586"
          y1="17.583"
          x2="7.90929"
          y2="14.6637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#871101" />
          <stop offset="0.99" stopColor="#911209" />
          <stop offset="1" stopColor="#911209" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_20783_112437"
          x1="3.1248"
          y1="10.2846"
          x2="3.79884"
          y2="14.6663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.23" stopColor="#E57252" />
          <stop offset="0.46" stopColor="#DE3B20" />
          <stop offset="0.99" stopColor="#A60003" />
          <stop offset="1" stopColor="#A60003" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_20783_112437"
          x1="7.7637"
          y1="6.19367"
          x2="8.01206"
          y2="10.6654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.23" stopColor="#E4714E" />
          <stop offset="0.56" stopColor="#BE1A0D" />
          <stop offset="0.99" stopColor="#A80D00" />
          <stop offset="1" stopColor="#A80D00" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_20783_112437"
          x1="11.8791"
          y1="2.06016"
          x2="12.6226"
          y2="5.26507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18" stopColor="#E46342" />
          <stop offset="0.4" stopColor="#C82410" />
          <stop offset="0.99" stopColor="#A80D00" />
          <stop offset="1" stopColor="#A80D00" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_20783_112437"
          x1="9.60845"
          y1="0.931823"
          x2="12.8135"
          y2="-0.671093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.54" stopColor="#C81F11" />
          <stop offset="0.99" stopColor="#BF0905" />
          <stop offset="1" stopColor="#BF0905" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_20783_112437"
          x1="0.315211"
          y1="9.16766"
          x2="0.578906"
          y2="11.8084"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.31" stopColor="#DE4024" />
          <stop offset="0.99" stopColor="#BF190B" />
          <stop offset="1" stopColor="#BF190B" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_20783_112437"
          x1="-1.49824"
          y1="12.5221"
          x2="10.9004"
          y2="-0.2302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BD0012" />
          <stop offset="0.07" stopColor="white" />
          <stop offset="0.17" stopColor="white" />
          <stop offset="0.27" stopColor="#C82F1C" />
          <stop offset="0.33" stopColor="#820C01" />
          <stop offset="0.46" stopColor="#A31601" />
          <stop offset="0.72" stopColor="#B31301" />
          <stop offset="0.99" stopColor="#E82609" />
          <stop offset="1" stopColor="#E82609" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_20783_112437"
          x1="8.00037"
          y1="13.8085"
          x2="4.26499"
          y2="12.8863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8C0C01" />
          <stop offset="0.54" stopColor="#990C00" />
          <stop offset="0.99" stopColor="#A80D0E" />
          <stop offset="1" stopColor="#A80D0E" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_20783_112437"
          x1="14.4174"
          y1="9.32607"
          x2="11.0996"
          y2="6.35174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E110B" />
          <stop offset="0.99" stopColor="#9E0C00" />
          <stop offset="1" stopColor="#9E0C00" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_20783_112437"
          x1="15.6363"
          y1="3.87027"
          x2="13.9837"
          y2="2.10312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#79130D" />
          <stop offset="0.99" stopColor="#9E120B" />
          <stop offset="1" stopColor="#9E120B" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_20783_112437"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.6146 6.40949) scale(4.06663 4.06567)"
        >
          <stop stopColor="#A80D00" />
          <stop offset="0.99" stopColor="#7E0E08" />
          <stop offset="1" stopColor="#7E0E08" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_20783_112437"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.98303 11.7673) scale(5.40605 5.40476)"
        >
          <stop stopColor="#A30C00" />
          <stop offset="0.99" stopColor="#800E08" />
          <stop offset="1" stopColor="#800E08" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_20783_112437"
          x1="2.15383"
          y1="15.9319"
          x2="0.807333"
          y2="11.3625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B2114" />
          <stop offset="0.43" stopColor="#9E100A" />
          <stop offset="0.99" stopColor="#B3100C" />
          <stop offset="1" stopColor="#B3100C" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_20783_112437"
          x1="12.488"
          y1="0.791376"
          x2="15.5079"
          y2="2.12469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B31000" />
          <stop offset="0.44" stopColor="#910F08" />
          <stop offset="0.99" stopColor="#791C12" />
          <stop offset="1" stopColor="#791C12" />
        </linearGradient>
      </defs>
    </svg>
  );
}
