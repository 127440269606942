import React, { useEffect, useState } from 'react';
import {
  AnimatePresence,
  isValidMotionProp,
  motion,
  useAnimation
} from 'framer-motion';
import { useRouter } from 'next/router';
import { Modal, ModalContent, ModalOverlay, chakra } from '@chakra-ui/react';
import { DevelopersMobile } from './developers-mobile';
import { CompanyMobile } from './company-mobile';
import { MobileSubMenu } from './mobile-submenu';
import { HomeMenu } from './home';
import { ProductMobileSections, ProductSections } from '../content';
import { DevelopersSections } from '../content';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

export const HEADER_HEIGHT = '80px';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function MobileMenuOpen({ isOpen, onClose }: Props): JSX.Element | null {
  const [selected, setSelected] = useState<string | null>(null);
  const { pathname } = useRouter();

  const logo = '/images/clerk-logomark-light.svg';

  useEffect(() => {
    onClose();
    setSelected(null);
  }, [pathname, onClose]);

  const OnCloseReset = () => {
    onClose();
    setSelected(null);
  };

  const ContentControls = useAnimation();

  return (
    <AnimatePresence>
      <Modal size={'full'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bgColor={'white'} />
        <ModalContent minH="full">
          <ChakraBox as={motion.div} animate={ContentControls}>
            {selected == null ? (
              <HomeMenu
                setSelected={setSelected}
                onClose={OnCloseReset}
                logo={logo}
              />
            ) : selected == 'Developers' ? (
              <DevelopersMobile
                setSelected={setSelected}
                onClose={OnCloseReset}
                title="Developers"
              />
            ) : selected == 'Company' ? (
              <CompanyMobile
                setSelected={setSelected}
                onClose={OnCloseReset}
                title="Company"
              />
            ) : selected == 'Pre-built Components' ? (
              <MobileSubMenu
                setSelected={setSelected}
                onClose={OnCloseReset}
                features={ProductMobileSections[0].features}
                title="Pre-built Components"
                customFlows
              />
            ) : selected == 'Authentication & Users' ? (
              <MobileSubMenu
                setSelected={setSelected}
                onClose={OnCloseReset}
                features={ProductSections[1].features}
                title="Authentication & Users"
                customFlows
              />
            ) : selected == 'Organizations (for B2B SaaS)' ? (
              <MobileSubMenu
                setSelected={setSelected}
                onClose={OnCloseReset}
                features={ProductSections[2].features}
                title="Organizations (for B2B SaaS)"
                customFlows
              />
            ) : selected == 'Resources' ? (
              <MobileSubMenu
                setSelected={setSelected}
                onClose={OnCloseReset}
                features={DevelopersSections[0].features}
                title="Resources"
              />
            ) : selected == 'SDKs & Framework Integrations' ? (
              <MobileSubMenu
                setSelected={setSelected}
                onClose={OnCloseReset}
                features={DevelopersSections[1].features}
                title="SDKs & Framework Integrations"
              />
            ) : selected == 'Third-Party Integrations' ? (
              <MobileSubMenu
                setSelected={setSelected}
                onClose={OnCloseReset}
                features={DevelopersSections[2].features}
                title="Third-Party Integrations"
              />
            ) : (
              <></>
            )}
          </ChakraBox>
        </ModalContent>
      </Modal>
    </AnimatePresence>
  );
}
