import { SystemStyleObjectRecord } from '@chakra-ui/react';
import { shadows } from './shadows';

const CARD_STYLES = {
  rounded: '2xl',
  py: 8,
  px: 7,
  flex: 1,
  boxShadow: 'dropShadow1'
};

export const layerStyles: SystemStyleObjectRecord = {
  card: CARD_STYLES,

  linkCard: {
    ...CARD_STYLES,
    cursor: 'pointer',
    transition: 'all 250ms',
    _hover: {
      boxShadow: 'dropShadow2'
    },
    _active: {
      boxShadow: 'dropShadow3',
      transform: 'scale(0.995)'
    },
    _focus: {
      boxShadow: `${shadows.dropShadow3} !important`
    }
  }
};
