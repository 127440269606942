import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';
import { SystemStyleObject } from '@chakra-ui/react';

// TODO: Revisit this
// https://github.com/chakra-ui/chakra-ui/issues/2347
const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    _placeholder: {
      color: 'gray.600'
    },
    _hover: {
      borderColor: 'primary.500'
    },
    _focus: {
      borderColor: 'primary.500',
      boxShadow: 'none !important'
    },
    _focusVisible: {
      borderColor: 'primary.500',
      boxShadow: 'none !important'
    }
  }
};

const size: Record<string, SystemStyleObject> = {
  md: {
    fontSize: 'xs',
    fontWeight: 'normal',
    // px: 4,
    // h: 10,
    borderRadius: 'md'
  }
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    field: size.md,
    addon: size.md
  }
};

const defaultProps = {
  size: 'md',
  variant: 'outline'
};

export const Input = {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps
};
