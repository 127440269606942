import { SVGProps } from 'react';

export function NextJSLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0C4.03594 0 0 4.03594 0 9C0 13.9641 4.03594 18 9 18C10.575 18 12.0516 17.5922 13.3313 16.8891L6.80625 7.77656V12.9234H5.85V5.87812H6.80625L13.9078 16.5375C16.3688 14.9344 18 12.1641 18 9C18 4.03594 13.9641 0 9 0ZM12.1078 11.8969L11.0531 10.3078V5.87812H12.1078V11.8969Z"
        fill="black"
      />
    </svg>
  );
}
