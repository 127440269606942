export function CompanyIcon(props) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3113 2.53126L13.004 4.83809C12.9315 4.9105 12.8368 4.95647 12.7351 4.96864C12.6333 4.9808 12.5304 4.95845 12.4429 4.90517C11.5353 4.35775 10.4886 4.08473 9.42916 4.11909C8.36975 4.15345 7.34295 4.49372 6.47273 5.09881C5.93754 5.47128 5.47282 5.93591 5.10029 6.47101C4.49581 7.3416 4.1558 8.36828 4.12117 9.42752C4.08655 10.4868 4.35877 11.5334 4.90509 12.4416C4.95799 12.529 4.98013 12.6316 4.96797 12.733C4.95581 12.8344 4.91005 12.9288 4.83799 13.0012L2.53076 15.308C2.4843 15.3548 2.42828 15.391 2.36653 15.4141C2.30479 15.4372 2.23879 15.4466 2.17304 15.4419C2.10729 15.4371 2.04335 15.4182 1.9856 15.3864C1.92785 15.3546 1.87765 15.3107 1.83844 15.2577C0.590077 13.5421 -0.0550736 11.4616 0.00368613 9.34079C0.0624459 7.22001 0.82181 5.17836 2.16325 3.53449C2.57277 3.03153 3.03264 2.57173 3.5357 2.16228C5.17972 0.821444 7.22144 0.0624345 9.34225 0.00368647C11.4631 -0.0550615 13.5437 0.589756 15.2594 1.83753C15.3128 1.8766 15.357 1.92678 15.3891 1.9846C15.4212 2.04241 15.4404 2.1065 15.4453 2.17244C15.4502 2.23838 15.4408 2.30461 15.4177 2.36655C15.3945 2.4285 15.3582 2.48469 15.3113 2.53126Z"
        fill="currentColor"
      />
      <path
        d="M15.3102 16.6789L13.003 14.3721C12.9305 14.2997 12.8358 14.2537 12.734 14.2415C12.6322 14.2294 12.5293 14.2517 12.4418 14.305C11.586 14.8211 10.6056 15.0939 9.60619 15.0939C8.60678 15.0939 7.62632 14.8211 6.77055 14.305C6.68302 14.2517 6.58012 14.2294 6.47836 14.2415C6.37661 14.2537 6.28188 14.2997 6.20938 14.3721L3.90214 16.6789C3.85376 16.7254 3.8162 16.782 3.79214 16.8446C3.76807 16.9073 3.75809 16.9744 3.76289 17.0414C3.76769 17.1083 3.78717 17.1734 3.81993 17.2319C3.85269 17.2905 3.89794 17.3411 3.95247 17.3802C5.59481 18.575 7.57361 19.2186 9.60466 19.2186C11.6357 19.2186 13.6145 18.575 15.2569 17.3802C15.3116 17.3414 15.3571 17.2909 15.3901 17.2325C15.4232 17.174 15.4429 17.109 15.448 17.0421C15.4531 16.9752 15.4434 16.9079 15.4196 16.8451C15.3958 16.7824 15.3584 16.7256 15.3102 16.6789V16.6789Z"
        fill="currentColor"
      />
      <path
        d="M9.60817 12.3521C11.1241 12.3521 12.3531 11.1234 12.3531 9.6077C12.3531 8.092 11.1241 6.86328 9.60817 6.86328C8.09221 6.86328 6.86328 8.092 6.86328 9.6077C6.86328 11.1234 8.09221 12.3521 9.60817 12.3521Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          id="paint0_linear_780_26830"
          x1="13.1428"
          y1="-1.40851"
          x2="-6.31202"
          y2="18.0513"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17CCFC" />
          <stop offset="0.5" stop-color="#5D31FF" />
          <stop offset="1" stop-color="#F35AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
