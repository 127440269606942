import React from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Box, Button, Flex, Icon, Link, useDisclosure } from '@chakra-ui/react';
import { PURPLE_BG_PAGES } from '@lib';
import MenuIcon from 'remixicon-react/Menu3FillIcon';
import { MobileMenuOpen } from './mobile-menu-open';
import { LayoutContainer } from '@components/common';

const HEADER_HEIGHT = '80px';

export function MobileMenuClosed(): JSX.Element | null {
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const { pathname } = useRouter();
  //const [isOpen, setIsOpen] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasPurpleBg = PURPLE_BG_PAGES.includes(pathname);
  const logo = hasPurpleBg
    ? '/images/clerk-logo-dark.svg'
    : '/images/clerk-logo.svg';
  const bg =
    hasScrolled && hasPurpleBg
      ? '#101347'
      : hasPurpleBg && !hasScrolled
      ? 'transparent'
      : !hasScrolled
      ? 'transparent'
      : 'gray.25';

  const textColor = hasPurpleBg ? 'white' : 'gray.700';
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScroll = () => {
        const scrollY = window.scrollY;

        if (scrollY > 50 && !hasScrolled) {
          setHasScrolled(true);
        } else if (scrollY <= 50 && hasScrolled) {
          setHasScrolled(false);
        }
      };

      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [hasScrolled]);

  return (
    <LayoutContainer
      verticalPadding="none"
      pos="fixed"
      bg={bg}
      top={0}
      w="full"
      borderBottom={hasScrolled ? '1px solid' : 'none'}
      borderColor="gray.200"
      shadow={hasScrolled ? 'base' : 'none'}
      transition="all 0.2s"
      zIndex={'sticky'}
    >
      <Flex
        as="header"
        justify="space-between"
        align="center"
        gap={6}
        h={HEADER_HEIGHT}
      >
        <Box zIndex={100}>
          <Link href={'/'}>
            <span className="sr-only">Clerk</span>
            <div className="image-wrap">
              {pathname.includes('/blog') && hasPurpleBg ? (
                <Image
                  src={'/images/blog-dark.svg'}
                  height={98}
                  width={160}
                  alt="Blog"
                  priority
                />
              ) : pathname.includes('/blog') ? (
                <Image
                  src={'/images/blog.svg'}
                  height={98}
                  width={160}
                  alt="Blog"
                  priority
                />
              ) : (
                <Image
                  src={logo}
                  height={24}
                  width={77}
                  alt="Clerk logo"
                  priority
                />
              )}
            </div>
          </Link>
        </Box>

        <Box>
          <Button aria-label="menu" variant="unstyled">
            <Icon
              as={MenuIcon}
              boxSize={6}
              color={textColor}
              onClick={onOpen}
            />
          </Button>
          <MobileMenuOpen isOpen={isOpen} onClose={onClose} />
        </Box>
      </Flex>
    </LayoutContainer>
  );
}
