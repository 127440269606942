import { Box, HStack, VStack, chakra, Grid, GridItem } from '@chakra-ui/react';
import { NavItemWithIcon } from '../nav-item-with-icon';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import React from 'react';
import SelectorBox from '../selector-box';
import { SliderSelector } from '../slider-selector';
import HoverButton from '../hover-button';
import { CustomFlowsIcon } from '../icons';
import { CustomFlowsGradient } from '../mobile/gradients';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

type Feature = {
  label: string;
  description?: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  href: string;
  gradient?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  external?: boolean;
};

export type Section = {
  name: string;
  description: string;
  features: Feature[];
};

interface SubmenuProps {
  sections: Section[];
}

const allContentHeight = 350;

const leftVStackBoxHeight = 90;
const selecterOffset = 22; // top-padding + a couple extra pixels
const exPaddingOffset = 1; // the last 2 cards look more balanced w/ an extra pixel

export function Product({ sections }: SubmenuProps): JSX.Element | null {
  const [selected, setSelected] = useState<Section>(sections[0]);

  const SectionControls = useAnimation();
  const ContentControls = useAnimation();

  useEffect(() => {
    if (selected.name == sections[0].name) {
      // Animation for Authentication & Users
      SectionControls.start({
        y: 0,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: 0,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    } else if (selected.name == sections[1].name) {
      // Animation for 'Pre-built Component' section
      SectionControls.start({
        y: `${leftVStackBoxHeight + exPaddingOffset}px`,

        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: -360,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    } else {
      // Animation for last section
      SectionControls.start({
        y: `${2 * leftVStackBoxHeight + exPaddingOffset}px`,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: -770,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    }
  }, [selected, SectionControls, ContentControls, sections]);

  return (
    <Box position={'relative'}>
      <HStack
        w={'830px'}
        h={`${allContentHeight}px`}
        spacing={'0px'}
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
      >
        <SliderSelector
          sections={sections}
          setSelected={setSelected}
          selected={selected}
        />

        <VStack h="full" bg="white" overflow="hidden" flex={1} pr="6px">
          <ChakraBox
            as={motion.div}
            animate={ContentControls}
            key={sections[0].name}
          >
            <Box h={`${allContentHeight}px`} pt="21px">
              <Grid
                templateRows="repeat(6, 1fr)"
                templateColumns="repeat(12, 1fr)"
                w="full"
                gap="6px"
              >
                {sections[0].features.map(feature => (
                  <GridItem key={feature.label} rowSpan={1} colSpan={6}>
                    <NavItemWithIcon
                      label={feature.label}
                      href={feature.href}
                      icon={feature.icon}
                      gradient={feature.gradient}
                      iconSize="18px"
                      external={feature.external}
                    />
                  </GridItem>
                ))}
                <GridItem rowSpan={1} colSpan={12}></GridItem>
                <GridItem rowSpan={1} colSpan={12}>
                  <HoverButton
                    text={
                      ' APIs, SDKs, and React hooks for your authentication flow.'
                    }
                    header="Custom flows"
                    icon={CustomFlowsIcon}
                    gradient={CustomFlowsGradient}
                    href="/docs/custom-flows/overview"
                  />
                </GridItem>
              </Grid>
            </Box>

            <Box h={`${allContentHeight}px`} pt="31px">
              <Grid
                templateRows="repeat(6, 1fr)"
                templateColumns="repeat(12, 1fr)"
                w="full"
                gap="6px"
              >
                {sections[1].features.map(feature => (
                  <GridItem key={feature.label} rowSpan={1} colSpan={6}>
                    <NavItemWithIcon
                      label={feature.label}
                      href={feature.href}
                      icon={feature.icon}
                      gradient={feature.gradient}
                      iconSize="18px"
                      external={feature.external}
                    />
                  </GridItem>
                ))}
                <GridItem rowSpan={1} colSpan={12}>
                  <HoverButton
                    text={
                      ' APIs, SDKs, and React hooks for your authentication flow.'
                    }
                    header="Custom flows"
                    icon={CustomFlowsIcon}
                    gradient={CustomFlowsGradient}
                    href="/docs/custom-flows/overview"
                  />
                </GridItem>
              </Grid>
            </Box>

            <Box h={`${allContentHeight}px`} pt="91px">
              <Grid
                templateRows="repeat(6, 1fr)"
                templateColumns="repeat(12, 1fr)"
                w="full"
                gap="6px"
              >
                {sections[2].features.map(feature => (
                  <GridItem key={feature.label} rowSpan={1} colSpan={6}>
                    <NavItemWithIcon
                      label={feature.label}
                      href={feature.href}
                      icon={feature.icon}
                      gradient={feature.gradient}
                      external={feature.external}
                      iconSize="18px"
                    />
                  </GridItem>
                ))}
                <GridItem rowSpan={1} colSpan={12}></GridItem>
                <GridItem rowSpan={1} colSpan={12}></GridItem>
                <GridItem rowSpan={1} colSpan={12}>
                  <HoverButton
                    text={
                      ' APIs, SDKs, and React hooks for your authentication flow.'
                    }
                    header="Custom flows"
                    icon={CustomFlowsIcon}
                    gradient={CustomFlowsGradient}
                    href="/docs/custom-flows/overview"
                  />
                </GridItem>
              </Grid>
            </Box>
          </ChakraBox>
        </VStack>
      </HStack>
    </Box>
  );
}
