import * as React from 'react';

export function DocumentationGradient(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 14.75L8.42496 14.6374C7.90398 13.856 7.64349 13.4652 7.29933 13.1824C6.99464 12.9319 6.64357 12.7441 6.2662 12.6295C5.83994 12.5 5.37033 12.5 4.43112 12.5H3.4C2.55992 12.5 2.13988 12.5 1.81901 12.3365C1.53677 12.1927 1.3073 11.9632 1.16349 11.681C1 11.3601 1 10.9401 1 10.1V3.65C1 2.80992 1 2.38988 1.16349 2.06901C1.3073 1.78677 1.53677 1.5573 1.81901 1.41349C2.13988 1.25 2.55992 1.25 3.4 1.25H3.7C5.38016 1.25 6.22024 1.25 6.86197 1.57698C7.42646 1.8646 7.8854 2.32354 8.17302 2.88803C8.5 3.52976 8.5 4.36984 8.5 6.05M8.5 14.75V6.05M8.5 14.75L8.57504 14.6374C9.09602 13.856 9.35651 13.4652 9.70067 13.1824C10.0054 12.9319 10.3564 12.7441 10.7338 12.6295C11.1601 12.5 11.6297 12.5 12.5689 12.5H13.6C14.4401 12.5 14.8601 12.5 15.181 12.3365C15.4632 12.1927 15.6927 11.9632 15.8365 11.681C16 11.3601 16 10.9401 16 10.1V3.65C16 2.80992 16 2.38988 15.8365 2.06901C15.6927 1.78677 15.4632 1.5573 15.181 1.41349C14.8601 1.25 14.4401 1.25 13.6 1.25H13.3C11.6198 1.25 10.7798 1.25 10.138 1.57698C9.57354 1.8646 9.1146 2.32354 8.82698 2.88803C8.5 3.52976 8.5 4.36984 8.5 6.05"
        stroke="url(#paint0_linear_749_27476)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_27476"
          x1="0.999999"
          y1="-1.5"
          x2="16"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
