import * as React from 'react';

export function EmailMagicLinksIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g clipPath="url(#clip0_749_26956)">
        <path
          d="M7.50064 9.75095C7.82273 10.1815 8.23366 10.5378 8.70555 10.7957C9.17745 11.0535 9.69927 11.2068 10.2356 11.2452C10.772 11.2836 11.3103 11.2062 11.8141 11.0183C12.318 10.8303 12.7755 10.5363 13.1556 10.156L15.4056 7.90595C16.0887 7.19869 16.4667 6.25143 16.4582 5.2682C16.4496 4.28496 16.0552 3.34441 15.36 2.64913C14.6647 1.95385 13.7241 1.55947 12.7409 1.55092C11.7577 1.54238 10.8104 1.92036 10.1031 2.60345L8.81314 3.88595M10.5006 8.25095C10.1785 7.82035 9.76762 7.46406 9.29573 7.20625C8.82383 6.94843 8.30201 6.79511 7.76565 6.7567C7.22929 6.71829 6.69095 6.79568 6.18713 6.98361C5.68331 7.17155 5.2258 7.46565 4.84564 7.84595L2.59564 10.096C1.91255 10.8032 1.53457 11.7505 1.54311 12.7337C1.55165 13.7169 1.94604 14.6575 2.64132 15.3528C3.3366 16.0481 4.27715 16.4424 5.26038 16.451C6.24362 16.4595 7.19088 16.0815 7.89814 15.3985L9.18064 14.116"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_749_26956">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
