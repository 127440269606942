import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Tabs: ComponentStyleConfig = {
  parts: ['tablist', 'tab', 'tabpanel'],
  baseStyle: {
    tab: {
      paddingInlineStart: '0',
      paddingInlineEnd: '0',
      pt: '0 !important',
      padding: '0',
      pb: '2',
      mr: '8'
    },
    tabpanel: {
      p: '0',
      pt: '12'
    }
  },
  variants: {
    line: props => ({
      tablist: {
        borderBottomWidth: '2px',
        borderColor: mode('gray.200', 'gray.600')(props)
      },
      tab: {
        color: mode('gray.500', 'gray.400')(props),
        fontSize: '0.875rem', // font-sizes dont work
        textStyle: 'md-medium',
        mb: '-2px', // Aligns tabs' border-bottom with container's border-bottom
        _selected: {
          color: mode('primary.500', 'primary.400')(props),
          borderBottomWidth: '2px'
        },
        _active: {
          bg: 'unset'
        },
        _focus: {
          boxShadow: 'none'
        }
      }
    })
  }
};
