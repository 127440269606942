import React from 'react';

export const SyncTabInstancesContext = React.createContext<{
  getActiveTab: (type: string) => string;
  setActiveTab: (type: string, tab: string) => void;
}>({} as any);

type Props = {
  children: React.ReactNode;
};

const LOCAL_STORAGE_KEY = 'clerk_tabs_preferences';

export function SyncTabInstancesProvider({ children }: Props) {
  const [contextActiveTab, setContextActiveTab] = React.useState<
    Record<string, string>
  >({});

  // Get initial state from localStorage
  React.useEffect(() => {
    const clerkTabsPreferences = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (clerkTabsPreferences) {
      const parsed = JSON.parse(clerkTabsPreferences);
      setContextActiveTab(parsed);
    }
  }, []);

  function getActiveTab(type: string) {
    return contextActiveTab[type];
  }

  function setActiveTab(type: string, tab: string) {
    if (!type) return;

    const updated = { ...contextActiveTab, [type]: tab };
    setContextActiveTab(updated);

    // store in localStorage
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated));
  }

  return (
    <SyncTabInstancesContext.Provider
      value={{
        getActiveTab,
        setActiveTab
      }}
    >
      {children}
    </SyncTabInstancesContext.Provider>
  );
}
