import * as React from 'react';

export function ClerkIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.337 2.3706L12.1766 4.53102C12.1087 4.59883 12.02 4.64189 11.9247 4.65328C11.8294 4.66467 11.7331 4.64374 11.6511 4.59384C10.8012 4.08116 9.82114 3.82548 8.82915 3.85766C7.83716 3.88984 6.87569 4.2085 6.06085 4.77519C5.55971 5.12402 5.12457 5.55916 4.77574 6.0603C4.20973 6.87563 3.89136 7.83715 3.85893 8.82916C3.82651 9.82117 4.08141 10.8014 4.59296 11.652C4.6425 11.7338 4.66323 11.8298 4.65184 11.9248C4.64046 12.0198 4.59761 12.1082 4.53013 12.176L2.36972 14.3364C2.32621 14.3802 2.27376 14.4141 2.21594 14.4357C2.15813 14.4574 2.09633 14.4662 2.03476 14.4618C1.9732 14.4573 1.91332 14.4396 1.85925 14.4098C1.80517 14.38 1.75816 14.3389 1.72145 14.2893C0.552528 12.6826 -0.0515691 10.7341 0.00345157 8.74793C0.0584722 6.76176 0.769516 4.84969 2.02559 3.31016C2.40906 2.83912 2.83967 2.40851 3.31071 2.02504C4.85012 0.769307 6.76192 0.0584718 8.74778 0.00345249C10.7336 -0.0515668 12.6819 0.552324 14.2884 1.7209C14.3384 1.75749 14.3798 1.80448 14.4099 1.85863C14.4399 1.91278 14.4579 1.9728 14.4625 2.03455C14.4671 2.09631 14.4583 2.15833 14.4366 2.21635C14.4149 2.27436 14.3809 2.32698 14.337 2.3706Z"
        fill="currentColor"
      />
      <path
        d="M14.3371 15.6217L12.1767 13.4612C12.1088 13.3934 12.0201 13.3504 11.9248 13.339C11.8295 13.3276 11.7332 13.3485 11.6512 13.3984C10.8499 13.8818 9.93182 14.1372 8.996 14.1372C8.06019 14.1372 7.14212 13.8818 6.34081 13.3984C6.25884 13.3485 6.1625 13.3276 6.06721 13.339C5.97193 13.3504 5.88324 13.3934 5.81534 13.4612L3.65492 15.6217C3.60962 15.6652 3.57445 15.7182 3.55192 15.7769C3.52938 15.8355 3.52004 15.8984 3.52454 15.9611C3.52903 16.0238 3.54727 16.0847 3.57794 16.1396C3.60862 16.1944 3.65099 16.2418 3.70205 16.2785C5.23989 17.3974 7.09277 18.0001 8.99457 18.0001C10.8964 18.0001 12.7493 17.3974 14.2871 16.2785C14.3384 16.2421 14.381 16.1948 14.4119 16.1401C14.4428 16.0854 14.4613 16.0245 14.4661 15.9618C14.4709 15.8991 14.4618 15.8361 14.4395 15.7773C14.4172 15.7186 14.3822 15.6654 14.3371 15.6217V15.6217Z"
        fill="currentColor"
      />
      <path
        d="M8.99601 11.5662C10.4155 11.5662 11.5662 10.4155 11.5662 8.99601C11.5662 7.57651 10.4155 6.42578 8.99601 6.42578C7.57651 6.42578 6.42578 7.57651 6.42578 8.99601C6.42578 10.4155 7.57651 11.5662 8.99601 11.5662Z"
        fill="currentColor"
      />
    </svg>
  );
}
