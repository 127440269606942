import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { ProductSections } from '../content';
import { useState } from 'react';

interface Props {
  setSelected?: (selected: string | null) => void;
}

export function ProductMobile({ setSelected }: Props): JSX.Element | null {
  const [selectedSection, setSelectedSection] = useState<string | null>(
    ProductSections[0].name
  );

  const handleSectionHover = (sectionName: string) => {
    setSelectedSection(sectionName);
  };

  return (
    <>
      <Flex
        flexDir="column"
        pos="relative"
        alignSelf={'center'}
        gap="10px"
        py="24px"
        px="30px"
      >
        {ProductSections.map((section, index) => {
          const isHovered = selectedSection === section.name;

          return (
            <Flex
              key={index} // Adding 'key' prop here
              onMouseEnter={() => handleSectionHover(section.name)}
              onTouchStart={() => handleSectionHover(section.name)}
              cursor="pointer"
              bg={isHovered ? '#F0F4F9' : 'transparent'}
              rounded="12px"
              shadow={isHovered ? '2px 2px 4px rgba(0, 0, 0, 0.15)' : ''}
              onClick={() => {
                if (setSelected) {
                  setSelected(section.name as string);
                }
              }}
              w="full"
              minW="80vw"
              h="75px"
              flexDir="row"
              py="10px"
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Flex flexDir="column" pl="14px">
                <Text
                  fontSize={14}
                  fontWeight="700"
                  fontFamily={'body'}
                  fontStyle="normal"
                  lineHeight="24px"
                  letterSpacing="-0.24px"
                >
                  {section.name}
                </Text>
                <Text
                  fontSize={12}
                  fontWeight={400}
                  fontFamily={'body'}
                  fontStyle="normal"
                  lineHeight="18px"
                  letterSpacing="-0.24px"
                  color="#626B7F"
                >
                  {section.description}
                </Text>
              </Flex>
              <Box pos={'relative'} pr="6px">
                <Icon
                  as={() => (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 12L10 8L6 4"
                        stroke="#6C47FF"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                />
              </Box>
            </Flex>
          );
        })}
      </Flex>
    </>
  );
}
