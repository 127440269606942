import * as React from 'react';

export function DiscordGradient(props) {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_749_28083)">
        <path
          d="M11.6431 0.499641C12.6982 0.70443 13.7106 1.0294 14.6629 1.45694C16.4864 4.17469 17.3939 7.21872 17.0948 10.7152C15.809 11.6368 14.5611 12.2277 13.3285 12.6348C13.1602 12.388 13.0014 12.1343 12.853 11.8738C13.1955 11.7258 13.527 11.5582 13.8495 11.3697L13.8499 11.3695C14.1673 11.1835 14.1805 10.7406 13.896 10.5286L13.8954 10.5282C13.811 10.4655 13.7265 10.399 13.645 10.3319L13.643 10.3303C13.4952 10.2101 13.2895 10.1817 13.1124 10.2647C10.4418 11.4974 7.52167 11.498 4.81575 10.2631L4.81256 10.2617C4.63923 10.1842 4.43554 10.2105 4.2873 10.3324L4.28666 10.3329C4.20687 10.3987 4.12194 10.4649 4.03586 10.5294C3.74649 10.7461 3.77245 11.1883 4.08468 11.3701L4.08725 11.3716C4.40808 11.5556 4.73948 11.7246 5.08339 11.8746C4.93657 12.1351 4.77751 12.3886 4.60718 12.6353C3.37951 12.2282 2.13152 11.6373 0.845674 10.7159C0.594663 7.67991 1.1899 4.61299 3.27502 1.45699C4.22869 1.02857 5.24188 0.703415 6.2973 0.499126C6.40308 0.701393 6.50751 0.918589 6.5843 1.10281L6.7197 1.42767L7.06761 1.37453C8.32152 1.183 9.59583 1.18279 10.8787 1.37474L11.223 1.42624L11.3593 1.10602C11.4381 0.921015 11.5403 0.701258 11.6431 0.499641ZM17.0787 10.8916C17.0788 10.8912 17.0788 10.8908 17.0788 10.8904C17.0788 10.8907 17.0788 10.891 17.0788 10.8913L17.0787 10.8916ZM4.57453 11.2504L4.57559 11.2496L4.57453 11.2504ZM5.2866 11.494L5.28563 11.4959L5.2866 11.494ZM8.11026 7.23832C8.12516 6.08234 7.27311 5.04603 6.10909 5.04603C4.9481 5.04603 4.10814 6.07965 4.10814 7.2351C4.10814 8.39307 4.96413 9.42396 6.10909 9.42396C7.26915 9.42396 8.1087 8.39234 8.11026 7.23832ZM13.845 7.23832C13.8599 6.08238 13.0079 5.04603 11.8441 5.04603C10.6829 5.04603 9.84293 6.07961 9.84293 7.2351C9.84293 8.39311 10.6989 9.42396 11.8441 9.42396C13.0121 9.42396 13.8435 8.39092 13.845 7.23832Z"
          stroke="url(#paint0_linear_749_27476)"
          strokeWidth="0.9"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_749_27476"
          x1="0.999999"
          y1="-1.5"
          x2="16"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
