export function OrgProfileIcon(props) {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1667 2.6C10.1667 3.74794 9.20863 4.7 8 4.7C6.79137 4.7 5.83333 3.74794 5.83333 2.6C5.83333 1.45206 6.79137 0.5 8 0.5C9.20863 0.5 10.1667 1.45206 10.1667 2.6ZM14.6111 4.33333C14.6111 5.00263 14.051 5.56667 13.3333 5.56667C12.6156 5.56667 12.0556 5.00263 12.0556 4.33333C12.0556 3.66404 12.6156 3.1 13.3333 3.1C14.051 3.1 14.6111 3.66404 14.6111 4.33333ZM8 7.43333C9.69955 7.43333 11.0556 8.77341 11.0556 10.4V12.5H4.94444V10.4C4.94444 8.77341 6.30045 7.43333 8 7.43333ZM3.94444 4.33333C3.94444 5.00263 3.38437 5.56667 2.66667 5.56667C1.94896 5.56667 1.38889 5.00263 1.38889 4.33333C1.38889 3.66404 1.94896 3.1 2.66667 3.1C3.38437 3.1 3.94444 3.66404 3.94444 4.33333ZM13.8333 12.5V10.4C13.8333 9.65944 13.6886 8.95135 13.4253 8.30185C14.5902 8.34869 15.5 9.28134 15.5 10.4V12.5H13.8333ZM0.5 12.5V10.4C0.5 9.28134 1.4098 8.34869 2.57469 8.30185C2.31143 8.95135 2.16667 9.65944 2.16667 10.4V12.5H0.5Z"
        stroke="currentColor"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2361_14963"
          x1="1"
          y1="-4.4331e-07"
          x2="17.5"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6344F5" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
