import { ComponentDefaultProps, ComponentStyleConfig } from '@chakra-ui/react';
import { colors } from '../foundations/colors';
import { PartsStyleInterpolation } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleInterpolation = props => {
  const cs = props.colorScheme;
  return {
    track: {
      p: 0,
      display: 'flex',
      alignItems: 'center',
      bg: 'gray.100',
      _focus: {
        boxShadow: 'none',
        '.chakra-switch__thumb': {
          boxShadow: `0px 0px 0px 2px ${colors.primary['300']}`
        }
      },
      _checked: {
        bg: `${cs}.200`,

        _disabled: {
          opacity: 1,
          '.chakra-switch__thumb': {
            bg: `${cs}.200`
          }
        }
      },
      _disabled: {
        opacity: 1,
        '.chakra-switch__thumb': {
          bg: '#F2F2F2'
        }
      }
    },
    thumb: {
      border: '0.125rem solid',
      borderColor: 'gray.100',
      _checked: {
        boxShadow: 'dropShadow1',
        bg: `${cs}.500`,
        borderColor: 'white'
      }
    }
  };
};

const sizes = {
  md: {
    track: {
      height: '3.5',
      width: '10'
    },
    thumb: {
      height: '5',
      width: '5',

      _checked: {
        transform:
          'translateX(calc(var(--chakra-sizes-10) - var(--chakra-sizes-5)))'
      }
    }
  },

  lg: {
    track: {
      height: '4',
      width: '12'
    },
    thumb: {
      height: '6',
      width: '6',

      _checked: {
        transform:
          'translateX(calc(var(--chakra-sizes-12) - var(--chakra-sizes-6)))'
      }
    }
  }
};

const defaultProps: ComponentDefaultProps = {
  size: 'lg'
};

export const Switch: ComponentStyleConfig = {
  parts: ['thumb', 'track', 'container'],
  baseStyle,
  sizes,
  defaultProps
};
