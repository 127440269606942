import * as React from 'react';

export function PasswordIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_749_26949)">
        <path
          d="M16.5 8.25V6.15C16.5 5.30992 16.5 4.88988 16.3365 4.56901C16.1927 4.28677 15.9632 4.0573 15.681 3.91349C15.3601 3.75 14.9401 3.75 14.1 3.75H3.9C3.05992 3.75 2.63988 3.75 2.31901 3.91349C2.03677 4.0573 1.8073 4.28677 1.66349 4.56901C1.5 4.88988 1.5 5.30992 1.5 6.15V8.85C1.5 9.69008 1.5 10.1101 1.66349 10.431C1.8073 10.7132 2.03677 10.9427 2.31901 11.0865C2.63988 11.25 3.05992 11.25 3.9 11.25H8.25M9 7.5H9.00375M12.75 7.5H12.7538M5.25 7.5H5.25375M14.4375 12.75V11.4375C14.4375 10.7126 13.8499 10.125 13.125 10.125C12.4001 10.125 11.8125 10.7126 11.8125 11.4375V12.75M9.1875 7.5C9.1875 7.60355 9.10355 7.6875 9 7.6875C8.89645 7.6875 8.8125 7.60355 8.8125 7.5C8.8125 7.39645 8.89645 7.3125 9 7.3125C9.10355 7.3125 9.1875 7.39645 9.1875 7.5ZM12.9375 7.5C12.9375 7.60355 12.8536 7.6875 12.75 7.6875C12.6464 7.6875 12.5625 7.60355 12.5625 7.5C12.5625 7.39645 12.6464 7.3125 12.75 7.3125C12.8536 7.3125 12.9375 7.39645 12.9375 7.5ZM5.4375 7.5C5.4375 7.60355 5.35355 7.6875 5.25 7.6875C5.14645 7.6875 5.0625 7.60355 5.0625 7.5C5.0625 7.39645 5.14645 7.3125 5.25 7.3125C5.35355 7.3125 5.4375 7.39645 5.4375 7.5ZM11.7 15.75H14.55C14.97 15.75 15.1801 15.75 15.3405 15.6683C15.4816 15.5963 15.5963 15.4816 15.6683 15.3405C15.75 15.1801 15.75 14.97 15.75 14.55V13.95C15.75 13.53 15.75 13.3199 15.6683 13.1595C15.5963 13.0184 15.4816 12.9037 15.3405 12.8317C15.1801 12.75 14.97 12.75 14.55 12.75H11.7C11.28 12.75 11.0699 12.75 10.9095 12.8317C10.7684 12.9037 10.6537 13.0184 10.5817 13.1595C10.5 13.3199 10.5 13.53 10.5 13.95V14.55C10.5 14.97 10.5 15.1801 10.5817 15.3405C10.6537 15.4816 10.7684 15.5963 10.9095 15.6683C11.0699 15.75 11.28 15.75 11.7 15.75Z"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_749_26949">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
