export function InvitationsGradient(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.308 1.97522L15.954 5.64512C16.1535 5.7748 16.2532 5.83964 16.3255 5.92613C16.3895 6.00269 16.4375 6.09124 16.4669 6.18661C16.5 6.29434 16.5 6.41331 16.5 6.65126V12.15C16.5 13.4102 16.5 14.0402 16.2548 14.5215C16.039 14.9449 15.6948 15.2891 15.2715 15.5048C14.7902 15.75 14.1601 15.75 12.9 15.75H5.1C3.83988 15.75 3.20982 15.75 2.72852 15.5048C2.30516 15.2891 1.96095 14.9449 1.74524 14.5215C1.5 14.0402 1.5 13.4102 1.5 12.15V6.65126C1.5 6.41331 1.5 6.29434 1.53313 6.18661C1.56245 6.09124 1.61051 6.00269 1.67449 5.92613C1.74676 5.83964 1.84651 5.7748 2.04601 5.64512L7.69203 1.97522M10.308 1.97522C9.83454 1.66748 9.59782 1.51362 9.34277 1.45375C9.11732 1.40083 8.88268 1.40083 8.65723 1.45375C8.40218 1.51362 8.16546 1.66748 7.69203 1.97522M10.308 1.97522L15.7261 5.49697C15.984 5.66463 16.113 5.74846 16.1576 5.85477C16.1967 5.94768 16.1967 6.05239 16.1576 6.14529C16.113 6.2516 15.984 6.33544 15.7261 6.5031L10.308 10.0249C9.83454 10.3326 9.59782 10.4864 9.34277 10.5463C9.11732 10.5992 8.88268 10.5992 8.65723 10.5463C8.40218 10.4864 8.16546 10.3326 7.69203 10.0249L2.27395 6.5031C2.016 6.33544 1.88703 6.2516 1.84237 6.14529C1.80333 6.05239 1.80333 5.94768 1.84237 5.85477C1.88703 5.74846 2.016 5.66463 2.27395 5.49697L7.69203 1.97522"
        stroke="url(#paint0_linear_749_27249)"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_749_27249"
          x1="2"
          y1="1"
          x2="17"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
