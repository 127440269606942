import Head from 'next/head';
import { useRouter } from 'next/router';
import url from 'url';
import { saneImageSrc } from '../../lib/sanity';

/**
 * Some platforms can't handle HTML tags in open graph data, and so we are stripping out the problem characters and leaving just the tag name in its place.
 */
function stripInvalidChars(str) {
  return str ? str.replace(/<(.+?) ?\/?>/g, '$1') : null;
}

export function SEO({ seo }) {
  const currentPath = useRouter().asPath;

  if (!seo) {
    console.warn(`***** No SEO for ${currentPath} *****`);
    return null;
  }

  const {
    title,
    description,
    ogImage,
    siteName,
    twitterHandle,
    siteUrl = ''
  } = seo;

  const saneImage = typeof ogImage === 'object' ? saneImageSrc(ogImage) : {};
  const mdxImage = typeof ogImage === 'string' ? ogImage : '';
  const resolvedOgImageUrl = saneImage.src
    ? `${saneImage.src}&w=1200&h=630&fm=png`
    : mdxImage;

  const canonical = url.resolve(siteUrl, currentPath);
  const type = canonical.includes('/blog/') ? 'article' : 'website';

  // TODO: pull data from blog post object
  // const { published_at, updated_at } = article || {};
  const pageTitle = currentPath?.includes('docs') ? title + ` | Clerk` : title;

  const fixedCanonical = !canonical.includes('https://clerk.com')
    ? 'https://clerk.com' + canonical
    : canonical;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={fixedCanonical} />

      <meta property="og:title" content={stripInvalidChars(pageTitle)} />
      <meta
        property="og:description"
        content={stripInvalidChars(description)}
      />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={fixedCanonical} />

      <meta
        property="og:image"
        content={
          saneImage.src ? `${saneImage.src}&w=1200&h=630&fm=png` : mdxImage
        }
      />
      <meta property="og:image:width" content={`${saneImage.width}`} />
      <meta property="og:image:height" content={`${saneImage.height}`} />

      <meta property="og:type" content={type} />
      {/* {published_at && (
        <meta property='article:published_time' content={published_at} />
      )}
      {updated_at && (
        <meta property='article:modified_time' content={updated_at} />
      )} */}

      <meta property="twitter:title" content={stripInvalidChars(pageTitle)} />
      <meta
        property="twitter:description"
        content={stripInvalidChars(description)}
      />
      <meta property="twitter:url" content={fixedCanonical} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content={twitterHandle} />
      <meta property="twitter:image" content={resolvedOgImageUrl} />
    </Head>
  );
}

export default SEO;
