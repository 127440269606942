import * as React from 'react';

export function OrgSwitcherIcon(props) {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 14.5703H3M3 14.5703L6 11.1417M3 14.5703L6 17.9989M3 5.99888H15M15 5.99888L12 2.57031M15 5.99888L12 9.42746"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
