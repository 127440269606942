import Image from 'next/legacy/image';
import { Box, PositionProps, ResponsiveObject } from '@chakra-ui/react';

type Props = {
  size?: ResponsiveObject<string> | string;
  top?: PositionProps['top'];
  bottom?: PositionProps['bottom'];
  left?: PositionProps['left'];
  right?: PositionProps['right'];
  rotate?: number;
  noBlur?: boolean; // Used for debugging only, to better see where the shape is
  priority?: boolean;
};

// !!! This component requires its parent to have a position of `relative` !!!

export function CShapeBlurPattern({
  size = '1219px',
  top,
  bottom,
  left,
  right,
  rotate,
  noBlur,
  priority = false
}: Props): JSX.Element {
  return (
    <Box
      pos="absolute"
      top={top}
      bottom={bottom}
      left={left}
      opacity={0.7}
      right={right}
      pointerEvents={'none'}
      draggable="false"
      userSelect={'none'}
      transform={rotate ? `rotate(-${rotate}deg)` : undefined}
      height={size}
      width={size}
    >
      <Image
        src={`/images/${noBlur ? 'c-shape-no-blur' : 'c-shape-blur'}.svg`}
        alt=""
        layout="fill"
        priority={priority}
      />
    </Box>
  );
}
