import { radioAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleObject } from '@chakra-ui/theme-tools';
import { ComponentStyleConfig } from '@chakra-ui/react';

const baseStyle: PartsStyleObject<typeof parts> = {
  control: {
    position: 'relative',

    '&::before': {
      position: 'absolute !important',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
};

const sizes = {
  md: {
    control: {
      '&::before': {
        height: '4px !important',
        width: '4px !important'
      }
    }
  },

  lg: {
    control: {
      '&::before': {
        height: '5px !important',
        width: '5px !important'
      }
    }
  }
};

export const Radio: ComponentStyleConfig = {
  parts: ['label'],

  baseStyle,
  sizes
};
