import * as React from 'react';

export function MapGradient(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_749_27760)">
        <path
          d="M6.75 13.5L1.5 16.5V4.5L6.75 1.5M6.75 13.5L12 16.5M6.75 13.5V1.5M12 16.5L16.5 13.5V1.5L12 4.5M12 16.5V4.5M12 4.5L6.75 1.5"
          stroke="url(#paint0_linear_749_27760)"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_749_27760"
          x1="2"
          y1="2"
          x2="19"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
        <clipPath id="clip0_749_27760">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
