import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import BlogCard from '../blog-card';
import { NavItemWithIcon } from '../nav-item-with-icon';
import { CompanySections } from '../content';
import { Post } from '@components/blog';

const sanityClient = require('@sanity/client');
const client = sanityClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  useCdn: true // Enable this for faster response times in production
});

export function Company(): JSX.Element {
  const [blogs, setBlogs] = useState<Post[]>([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const query = `*[_type == "blog" && live == true && !(_id in path('drafts.**'))] | order(date desc, _createdAt desc) [0...3] {
          title,
          slug,
          "author": author->{name, picture},
          date,
          "coverImage": mainImage.asset->url
        }`;
        const result = await client.fetch(query);
        setBlogs(result);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <HStack
      display={'flex'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      h="353px"
      w="830px"
      bg="white"
      zIndex={100}
      rounded="xl"
    >
      <Box>
        <Flex pl={4} gap="6px" flexDir="column" pt="24px">
          {CompanySections[0].features.map(
            ({ label, href, icon, gradient, external }) => (
              <NavItemWithIcon
                key={label}
                label={label}
                href={href}
                icon={icon}
                gradient={gradient}
                iconSize="18px"
                maxW="210px"
                external={external}
              />
            )
          )}
        </Flex>
      </Box>

      <Box
        h="full"
        w="1px"
        bgGradient="linear-gradient(to bottom, #6C47FF, #17CCFC)"
        bgClip="border-box"
        opacity={0.8}
        zIndex={0}
        pos={'relative'}
        left="14px"
      ></Box>

      <Box pl="32px">
        <Text
          fontWeight={700}
          fontSize={'14px'}
          fontFamily={'heading'}
          pt={'22px'}
          pos="relative"
          right="1px"
          color="black"
        >
          From the Blog
        </Text>
        {blogs ? (
          <VStack gap={4} zIndex={100} w="full" flex={1} pr={4} pt="24px">
            {blogs?.map((blog, index) => (
              <Box key={index}>
                <BlogCard blog={blog} />
              </Box>
            ))}
          </VStack>
        ) : (
          <></>
        )}
      </Box>
    </HStack>
  );
}
