import {
  Box,
  Button,
  Icon,
  VStack,
  HStack,
  Text,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  UseDisclosureReturn
} from '@chakra-ui/react';
import { CloseIcon } from '../plain-support/icons/closeIcon';
import React, { useState } from 'react';
import {
  Destination,
  DestinationPreferences,
  Group,
  destinationPreferencesFromGroups,
  isGroupEnabled
} from './categories';

const UI_GROUPS: Array<{
  key: Group;
  canBeToggled: boolean;
  name: string;
  description: string;
}> = [
  // Clerk is the only service that falls under the functional category
  // we have no other integration in this category  so we will not show a toggle for this one
  // {
  //   key: 'functional',
  //   canBeToggled: true,
  //   name: 'Functional',
  //   description:
  //     'To monitor the performance of our site and to enhance your browsing experience. For example, these tools enable you to communicate with us via live chat.'
  // },
  {
    key: 'essential',
    canBeToggled: false,
    name: 'Essential',
    description:
      'We use browser cookies that are necessary for the site to work as intended. For example, we store your website data collection preferences so we can honor them if you return to our site. You can disable these cookies in your browser settings but if you do the site may not work as intended.'
  },
  {
    key: 'marketing',
    canBeToggled: true,
    name: 'Marketing and Analytics',
    description:
      'To understand user behaviour in order to provide you with a more relevant browsing experience or personalise the content on our site. For example, we collect information about which pages you visit to help us present more relevant information.'
  },
  {
    key: 'advertising',
    canBeToggled: true,
    name: 'Advertising',
    description:
      'To personalize and measure the effectiveness of advertising on our site and other websites. For example, we may serve you a personalized ad based on the pages you visit on our site.'
  }
];

export type PreferencesDialog = {
  destinations: Destination[];
  destinationPreferences: DestinationPreferences;
  isConsentRequired: boolean;
  savePreferences: (preferences: DestinationPreferences) => void;
} & UseDisclosureReturn;

const getGroupStateFromCurrentDestinationPreferences = (
  props: PreferencesDialog
) => {
  return UI_GROUPS.reduce(
    (acc, group) => {
      acc[group.key] = isGroupEnabled(
        group.key,
        props.destinations,
        props.destinationPreferences
      );
      return acc;
    },
    {} as Record<string, boolean>
  );
};

const setGroupStateToTrue = () => {
  return UI_GROUPS.reduce(
    (acc, group) => {
      acc[group.key] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );
};

export const PreferencesDialog = (props: PreferencesDialog) => {
  const [groupState, setGroupState] = useState({});

  React.useEffect(() => {
    if (
      props.destinationPreferences === undefined &&
      !props.isConsentRequired
    ) {
      setGroupState(setGroupStateToTrue);
    } else {
      setGroupState(getGroupStateFromCurrentDestinationPreferences(props));
    }
  }, [props.destinationPreferences, props.destinations]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupState({
      ...groupState,
      [e.target.name]: !groupState[e.target.name as keyof typeof groupState]
    });
  };

  const onSaveSettings = () => {
    props.savePreferences(
      destinationPreferencesFromGroups(groupState, props.destinations)
    );
    props.onClose();
  };

  return (
    <Modal {...props}>
      <ModalOverlay
        backdropFilter={'blur(6px)'}
        background={'whiteAlpha.500'}
        transition={'all 200ms'}
      />
      <ModalContent
        bg="#F5F5F5"
        w="xl"
        fontSize="cookieConsent"
        borderRadius="xl"
        p="0"
        boxShadow="cookiesModal"
      >
        <ModalHeader py="4" px="5" mb="0">
          <HStack align="start">
            <Box>
              <Text
                fontSize="md"
                fontWeight="medium"
                lineHeight="6"
                color="#131316"
              >
                Website Data Collection Preferences
              </Text>
              <Text fontSize="xs" color="gray.500">
                We use data collected by cookies and JavaScript libraries to
                improve your browsing experience, analyze site traffic, deliver
                personalized advertisements, and increase the overall
                performance of our site.
              </Text>
            </Box>
            <Box as="button">
              <Icon
                as={CloseIcon}
                h="5"
                w="5"
                color="#9394A1"
                bg="gray.100"
                p="0.5"
                borderRadius="base"
                onClick={props.onClose}
              ></Icon>
            </Box>
          </HStack>
        </ModalHeader>
        <ModalBody
          px="0"
          boxShadow="cookiesModalContent"
          borderRadius="lg"
          bg="white"
        >
          {UI_GROUPS.map((group, index) => (
            <HStack
              key={index}
              align="start"
              py="4"
              px="5"
              borderBottom="1px solid rgba(147, 148, 161, 0.10)"
            >
              <VStack align="start" spacing="2" w="full">
                <Box
                  bg="gray.800"
                  color="white"
                  p="0.5"
                  borderRadius="base"
                  fontWeight="medium"
                  fontSize="xxs"
                  lineHeight="shorter"
                  border="1px solid var(--gray-gray-800, #2F3037)"
                >
                  {group.name}
                </Box>

                <Box fontSize="xs" color="gray.500" maxW="md">
                  {group.description}
                </Box>
              </VStack>
              {group.canBeToggled && (
                <Switch
                  size="md"
                  onChange={handleChange}
                  defaultChecked={
                    groupState[group.key as keyof typeof groupState]
                  }
                  name={group.key}
                  sx={{
                    '.chakra-switch__track': {
                      background: groupState[
                        group.key as keyof typeof groupState
                      ]
                        ? '#131316 !important'
                        : 'gray.100 !important',
                      height: '5'
                    },
                    '.chakra-switch__thumb': {
                      background: '#FFF !important',
                      borderColor: groupState[
                        group.key as keyof typeof groupState
                      ]
                        ? '#131316 !important'
                        : 'gray.100 !important'
                    }
                  }}
                />
              )}
            </HStack>
          ))}
        </ModalBody>

        <ModalFooter py="4" px="5" mt="0">
          <HStack w="full" justifyContent="end">
            <HStack>
              <Button variant="ghost" onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant="primary-gradient"
                minHeight="8"
                onClick={onSaveSettings}
              >
                Save settings
              </Button>
            </HStack>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
