import {
  createImageUrlBuilder,
  createPreviewSubscriptionHook
} from 'next-sanity';

import { sanityConfig } from './config';

export const imageBuilder = createImageUrlBuilder(sanityConfig);

export const urlForImage = source =>
  imageBuilder.image(source).auto('format').fit('max');

export const usePreviewSubscription =
  createPreviewSubscriptionHook(sanityConfig);

const pattern = /^image-([a-f\d]+)-(\d+x\d+)-(\w+)$/;
export const decodeAssetId = id => {
  const [, assetId, dimensions, format] = pattern.exec(id);
  const [width, height] = dimensions.split('x').map(v => parseInt(v, 10));

  return {
    assetId,
    dimensions: { width, height },
    format
  };
};

export const saneImageSrc = source => {
  const { dimensions } = decodeAssetId(source?.asset._ref);
  return {
    height: dimensions.height,
    width: dimensions.width,
    src: urlForImage(source.asset).url()
  };
};
