import Link from 'next/link';
import { Flex, Icon, LayoutProps, Text } from '@chakra-ui/react';
import { RemixiconReactIconComponentType } from 'remixicon-react';
import { useState } from 'react';

type Props = {
  label: string;

  icon?: RemixiconReactIconComponentType;
  iconBoxBg?: string;

  labelColor?: string;
  iconSize?: LayoutProps['boxSize'];
  href?: string;
  onClick?: () => void;
  gradient?: RemixiconReactIconComponentType;
};

export function NavItemWithIconMobile({
  label,
  icon,
  iconBoxBg,
  labelColor,
  iconSize,
  href,
  gradient,
  onClick
}: Props) {
  const isNewTab = href && href.includes('/docs'); // Check if href includes "/docs"
  const [isFocused, setIsFocused] = useState(false);

  const handleGroupFocus = () => {
    setIsFocused(true);
  };

  const handleGroupUnFocus = () => {
    setIsFocused(false);
  };

  return (
    <Link
      legacyBehavior
      href={href ? href : ''}
      target={isNewTab ? '_blank' : undefined} // Open in a new tab if isNewTab is true
      rel={isNewTab ? 'noopener noreferrer' : undefined}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        cursor={'pointer'}
        py="8px"
        px="12px"
        w="full"
        role="group"
        rounded="10px"
        onClick={onClick ? onClick : undefined}
        onFocus={handleGroupFocus}
        onMouseEnter={handleGroupFocus}
        onTouchStart={handleGroupFocus}
        onMouseLeave={handleGroupUnFocus}
        onTouchEnd={handleGroupUnFocus}
        onTouchCancel={handleGroupUnFocus}
      >
        {icon && (
          <Flex
            boxSize={'40px'}
            rounded="md"
            justifyContent={'center'}
            alignItems={'center'}
            minW="40px"
            bg={iconBoxBg ? iconBoxBg : 'transparent'}
            mr="16px"
          >
            {isFocused ? (
              <Icon as={gradient} boxSize={iconSize || 6} />
            ) : (
              <Icon as={icon} boxSize={iconSize || 6} />
            )}
          </Flex>
        )}

        <Text
          w="100%"
          color={labelColor ? labelColor : 'gray.700'}
          fontFamily={'body'}
          fontSize={'14px'}
          zIndex={100}
          fontWeight={500}
          whiteSpace={'nowrap'}
          lineHeight="24px"
          letterSpacing="-0.28px"
        >
          {label}
        </Text>
      </Flex>
    </Link>
  );
}
