import { Flex, CloseButton, VStack, Box } from '@chakra-ui/react';
import { ProductMobile } from './product-mobile';
import Image from 'next/image';
import React, { useEffect, useRef } from 'react';
import { DocumentationIcon } from '../icons';
import {
  CustomersIcon,
  PricingIcon,
  StartupsIcon,
  DevelopersIcon,
  CustomFlowsIcon,
  CompanyIcon,
  StartBuildingIcon
} from './icons';
import { NavItemWithIconMobile } from './nav-item-mobile';
import {
  CustomFlowsGradient,
  CustomersGradient,
  DevelopersGradient,
  PricingGradient,
  StartupsGradient,
  CompanyGradient,
  DocumentationGradient
} from './gradients';

const sections = [
  {
    label: 'Custom Flows',
    icon: CustomFlowsIcon,
    gradient: CustomFlowsGradient,

    href: '/docs/custom-flows/overview'
  },
  {
    label: 'Documentation',
    icon: DocumentationIcon,
    gradient: DocumentationGradient,

    href: '/docs'
  },
  {
    label: 'Developers',
    icon: DevelopersIcon,
    gradient: DevelopersGradient,

    onClick: true,
    value: 'Developers'
  },
  {
    label: 'Company',
    icon: CompanyIcon,
    gradient: CompanyGradient,

    onClick: true,
    value: 'Company'
  },
  {
    label: 'For Startups',
    icon: StartupsIcon,
    gradient: StartupsGradient,

    href: '/startups'
  },
  {
    label: 'Customers',
    icon: CustomersIcon,
    gradient: CustomersGradient,

    href: '/customers'
  },
  {
    label: 'Pricing',
    icon: PricingIcon,
    gradient: PricingGradient,

    href: '/pricing'
  }
];

interface Props {
  setSelected?: (selected: string | null) => void;
  onClose?: () => void;
  logo: string;
}

export const HomeMenu = ({
  setSelected,
  onClose,
  logo
}: Props): JSX.Element => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (elementRef.current) {
        elementRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 50);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Flex flexDir="column" h="full" w="full" pb="31px">
      <Flex
        flexDir="row"
        justifyContent="space-between"
        w="full"
        pl="20px"
        pr="16px"
      >
        <Box pt="9px" h="36px" w="142px">
          <Image src={logo} height={24} width={24} alt="clerk" />
        </Box>
        <Box pt="6px">
          <CloseButton onClick={onClose} size="sm" />
        </Box>
      </Flex>

      <VStack>
        <ProductMobile setSelected={setSelected} />

        <Box
          h="1px"
          w="full"
          bgGradient="linear-gradient(to bottom, #6C47FF, #17CCFC)"
          bgClip="border-box"
        ></Box>
        <VStack pt="28px" gap="20px" w="full" px="16px">
          {sections.map((section, index) => (
            <NavItemWithIconMobile
              key={index}
              {...section}
              onClick={() => {
                section.onClick && setSelected
                  ? setSelected(section.label as string)
                  : undefined;
              }}
              iconSize="18px"
            />
          ))}
          <NavItemWithIconMobile
            label="Start Building"
            icon={StartBuildingIcon}
            gradient={StartBuildingIcon}
            iconBoxBg="linear-gradient(45deg, #6C47FF 0%, #17CCFC 100%)"
            href={process.env.NEXT_PUBLIC_DASHBOARD_URL + '/sign-up'}
            labelColor="primary.500"
            iconSize="18px"
          />
        </VStack>
      </VStack>
    </Flex>
  );
};
